<template>
  <dashboard v-cloak v-slot:default="slotProps">
    <!-- Kompletter Hack, pfusch des jahrhunderts -->
    <span hidden>{{ (selected_user_id = slotProps.user) }}</span>
    <h1>Timerange View</h1>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dates"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Select Range"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" no-title scrollable range>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(dates)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-card-title class="header_color">Statistics for range</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <template v-if="monthly_data_days[0]" v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left"></th>
                        <th class="header_color text-left">
                          EUR
                        </th>
                        <th class="header_color text-left">
                          USD
                        </th>
                        <th
                          v-for="quote in quote_profits"
                          :key="quote.quote"
                          class="text-left"
                        >
                          {{ quote.quote }}
                        </th>
                        <th class="text-left">
                          total profit BTC
                        </th>
                        <th class="text-left">
                          % on account
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Total</td>
                        <td>{{ monthly_data_days[0].month_profit_eur }}</td>
                        <td>{{ monthly_data_days[0].month_profit_usd }}</td>
                        <td v-for="quote in quote_profits" :key="quote.quote">
                          {{ quote.profit.toFixed(8) }}
                        </td>
                        <td>
                          {{ monthly_data_days[0].month_profit_btc.toFixed(8) }}
                        </td>
                        <td>
                          {{
                            monthly_data_days[0].month_percent_return_on_account
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Per Day</td>
                        <td>
                          {{
                            (
                              monthly_data_days[0].month_profit_eur /
                              monthly_data_days[0].days_so_far
                            ).toFixed(2)
                          }}
                        </td>
                        <td>
                          {{
                            (
                              monthly_data_days[0].month_profit_usd /
                              monthly_data_days[0].days_so_far
                            ).toFixed(2)
                          }}
                        </td>
                        <td v-for="quote in quote_profits" :key="quote.quote">
                          {{
                            (
                              quote.profit / monthly_data_days[0].days_so_far
                            ).toFixed(8)
                          }}
                        </td>
                        <td>
                          {{
                            (
                              monthly_data_days[0].month_profit_btc /
                              monthly_data_days[0].days_so_far
                            ).toFixed(8)
                          }}
                        </td>
                        <td>
                          {{
                            (
                              monthly_data_days[0]
                                .month_percent_return_on_account /
                              monthly_data_days[0].days_so_far
                            ).toFixed(5)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Estimated Forecast</td>
                        <td>
                          {{
                            (
                              (monthly_data_days[0].month_profit_eur /
                                monthly_data_days[0].days_so_far) *
                              days
                            ).toFixed(2)
                          }}
                        </td>
                        <td>
                          {{
                            (
                              (monthly_data_days[0].month_profit_usd /
                                monthly_data_days[0].days_so_far) *
                              days
                            ).toFixed(2)
                          }}
                        </td>
                        <td v-for="quote in quote_profits" :key="quote.quote">
                          {{
                            (
                              (quote.profit /
                                monthly_data_days[0].days_so_far) *
                              days
                            ).toFixed(8)
                          }}
                        </td>
                        <td>
                          {{
                            (
                              (monthly_data_days[0].month_profit_btc /
                                monthly_data_days[0].days_so_far) *
                              days
                            ).toFixed(8)
                          }}
                        </td>
                        <td>
                          {{
                            (
                              (monthly_data_days[0]
                                .month_percent_return_on_account /
                                monthly_data_days[0].days_so_far) *
                              days
                            ).toFixed(5)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-card-title class="header_color">
            <v-row>
              <v-col cols="6">
                Market Pairs
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers_pairs"
              :items="pairs"
              :search="search"
              :items-per-page="20"
              class="elevation-1"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-card-title class="header_color">Daily Metrics</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="daily_metrics"
                  :expanded.sync="expanded"
                  item-key="date"
                  show-expand
                  class="elevation-1"
                >
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <br />
                      <v-row>
                        <v-col
                          cols="4"
                          v-for="quote in item.quote_profits"
                          :key="quote.quote_id"
                        >
                          <h3 class="header_color">
                            Profit {{ quote.quote_name }}
                          </h3>
                          <v-text-field
                            label="Sells"
                            :value="quote.count_sells_quote"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Profit Quote"
                            :value="quote.sum_profit_quote"
                            :suffix="quote.quote_name"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Profit BTC"
                            :value="quote.sum_profit_btc"
                            suffix="BTC"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Profit ETH"
                            :value="quote.sum_profit_eth"
                            suffix="ETH"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Profit USD"
                            :value="quote.sum_profit_usd"
                            suffix="$"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Profit EUR"
                            :value="quote.sum_profit_eur"
                            suffix="€"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </dashboard>
</template>

<script>
import dashboard from "./Dashboard.vue";
import axios from "axios";

export default {
  name: "Daily",
  components: {
    dashboard,
  },
  data() {
    return {
      expanded: [],
      headers: [
        { text: "Date", value: "date" },
        { text: "Trades", value: "sum_trades" },
        { text: "Sells", value: "sum_sells" },
        { text: "Avg % / Trade", value: "avg_percent_profit" },
        { text: "Highest % Profit", value: "max_percent_profit" },
        { text: "Lowest % Profit", value: "min_percent_profit" },
        {
          text: "% Return On Account",
          value: "percent_return_on_account",
        },
        { text: "Sum Profit BTC", value: "sum_profit_btc" },
        { text: "Sum Profit ETH", value: "sum_profit_eth" },
        { text: "Sum Profit USD", value: "sum_profit_usd" },
        { text: "Sum Profit EUR", value: "sum_profit_eur" },
      ],
      headers_pairs: [
        { text: "Quote", value: "quote" },
        { text: "Base", value: "base" },
        { text: "Sells", value: "sells" },
        { text: "Avg % / Trade", value: "avg_profit_percentage" },
        { text: "Profit Quote / Trade", value: "avg_quote_profit" },
        { text: "Sum Profit Quote", value: "sum_quote_profit" },
        { text: "Profit BTC / Trade", value: "avg_profit_btc" },
        { text: "Sum Profit BTC", value: "sum_profit_btc" },
        { text: "Sum Profit ETH", value: "sum_profit_eth" },
        { text: "Sum Profit USD", value: "sum_profit_usd" },
        { text: "Sum Profit EUR", value: "sum_profit_eur" },
      ],
      orders: [],
      users: [],
      daily_quote_profits: [],
      daily_metrics: [],
      monthly_data_days: [],
      quote_profits: [],
      pairs: [],
      selected_user_id: 0,
      menu: false,
      month: "",
      search: "",
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dates: [],
      days: 31,
      loading: false,
    };
  },
  mounted() {
    this.selected_user_id = localStorage.getItem("user_id");
  },
  filters: {
    setDecimals(value) {
      return value.toFixed(8);
    },
  },
  watch: {
    dates: function() {
      this.month = this.monthNames[new Date(this.dates[0]).getMonth()];
      if (this.selected_user_id != 0) {
        if (this.dates[0] && this.dates[1]) {
          this.getMonthlyData();
        }
      }
    },
    selected_user_id: function() {
      if (this.dates[0] && this.dates[1]) {
        this.getMonthlyData();
      }
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" - ");
    },
  },
  methods: {
    async getMonthlyData() {
      this.loading = true;
      let start_date = this.dates[0];
      let end_date = this.dates[1];
      this.monthly_data_days = [];
      this.daily_metrics = [];
      this.quote_profits = [];
      this.pairs = [];
      let token = await this.$auth.getTokenSilently();
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/monthly_orders?start_date=" +
            start_date +
            "&end_date=" +
            end_date +
            "&user_id=" +
            this.selected_user_id,
          {
            headers: {
              Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then((response) => {
          this.monthly_data_days = response.data.monthly_data_days;
          this.daily_metrics = response.data.daily_metrics;
          this.quote_profits = response.data.quote_profits;
          this.pairs = response.data.pairs;
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.header_color {
  color: #a16316;
}
</style>
