<template>
  <dashboard v-cloak v-slot:default="slotProps">
    <!-- Kompletter Hack, pfusch des jahrhunderts -->
    <span hidden>{{ (selected_user_id = slotProps.user) }}</span>
    <h2>Closed Orders</h2>
    <br />
    <v-card outlined>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="orders"
          :items-per-page="20"
          class="elevation-1"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </dashboard>
</template>

<script>
import dashboard from "./Dashboard.vue";
import axios from "axios";
export default {
  name: "Orders",
  components: {
    dashboard,
  },
  data() {
    return {
      headers: [
        { text: "Base", value: "base" },
        { text: "Quote", value: "quote" },
        { text: "Exchange", value: "exchange" },
        { text: "Profit", value: "profit" },
        { text: "Profit %", value: "profit_percentage" },
        { text: "Time", value: "timestamp" },
        { text: "Fee", value: "gsmg_fee_wei" },
        // { text: "User", value: "gsmg_user_id" },
      ],
      orders: [],
      selected_user_id: 0,
    };
  },
  mounted() {},
  watch: {
    selected_user_id: function() {
      this.getOrders();
    },
  },
  methods: {
    async getOrders() {
      let token = await this.$auth.getTokenSilently();
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/closed_orders?user_id=" +
            this.selected_user_id,
          {
            headers: {
              Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then((response) => {
          this.orders = response.data.orders;
        });
    },
  },
};
</script>

<style></style>
