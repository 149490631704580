<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      page: 0,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
