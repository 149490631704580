<template>
  <dashboard v-cloak v-slot:default="slotProps">
    <span hidden>{{ (selected_user_id = slotProps.user) }}</span>
    <br />
    <v-row class="mb-2">
      <p class="pl-3 font-weight-light">Management Dashboard</p>
    </v-row>
    <v-row>
      <v-col cols="12">
        <template>
          <v-row>
            <v-col cols="9" class="pl-4">
              <v-tabs v-model="mgmt_tab" color="none">
                <v-tab class="font-weight-light">General Info</v-tab>
                <v-tab class="font-weight-light">Fee Current</v-tab>
                <v-tab class="font-weight-light">Fee Last</v-tab>
                <v-tab class="font-weight-light">Fee Second To Last</v-tab>
                <v-tab class="font-weight-light">Fee All</v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="3" class="pr-4">
              <v-card class="fill-height">
                <v-card-text class="text-center pa-3">
                  <v-tabs-items v-model="mgmt_tab">
                    <v-tab-item class="font-weight-light text-right">
                      <template v-if="loaded_managed && managed_values_sum[0]">
                        {{ managed_values_sum[0].btc }} BTC -
                        {{ managed_values_sum[0].usd }} $
                      </template>
                    </v-tab-item>

                    <v-tab-item class="font-weight-light text-right">
                      <template
                        v-if="loaded_current_fee && current_month_sum_fiat[0]"
                      >
                        {{ current_month_sum_fiat[0].usd }} $ -
                        {{ current_month_sum_fiat[0].eur }} €
                      </template>
                    </v-tab-item>

                    <v-tab-item class="font-weight-light text-right">
                      <template
                        v-if="loaded_current_fee && last_month_sum_fiat[0]"
                      >
                        {{ last_month_sum_fiat[0].usd }} $ -
                        {{ last_month_sum_fiat[0].eur }} €
                      </template>
                    </v-tab-item>

                    <v-tab-item class="font-weight-light text-right">
                      <template
                        v-if="
                          loaded_current_fee && second_to_last_month_sum_fiat[0]
                        "
                      >
                        {{ second_to_last_month_sum_fiat[0].usd }} $ -
                        {{ second_to_last_month_sum_fiat[0].eur }} €
                      </template>
                    </v-tab-item>

                    <v-tab-item class="font-weight-light text-right">
                      <template v-if="loaded_all_fee && sum_fiat[0]">
                        {{ sum_fiat[0].usd }} $ - {{ sum_fiat[0].eur }} €
                      </template>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <br />
          <v-tabs-items id="main_tab_items" v-model="mgmt_tab">
            <v-tab-item>
              <v-row class="pa-1">
                <v-col cols="6">
                  <v-card :loading="!loaded_subscriptions">
                    <v-card-subtitle class="pt-4 font-weight-light">
                      Subscription Expiration
                    </v-card-subtitle>
                    <v-card-text>
                      <template>
                        <v-simple-table v-if="sub_info[0]" dense>
                          <template>
                            <thead>
                              <tr>
                                <th class="text-left font-weight-light">
                                  User
                                </th>
                                <th class="text-left font-weight-light">
                                  Expiration Datetime
                                </th>
                                <!-- <th class="text-left font-weight-light">
                                  ETH Deposit Address
                                </th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(sub, i) in sub_info" :key="i">
                                <td class="font-weight-light">
                                  {{ sub.gsmg_user_id }} - ({{ sub.user_id }})
                                  {{ sub.first_name }} {{ sub.last_name }}
                                </td>
                                <td class="font-weight-light">
                                  {{ sub.subscription_expiration_date }}
                                </td>
                                <!-- <td class="font-weight-light">
                                  {{ sub.subscription_eth_deposit_address }}
                                </td> -->
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <p v-else class="font-weight-light text-center ma-0">
                          No subscription information available.
                        </p>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card :loading="!loaded_exchanges">
                    <v-card-subtitle class="pt-4 font-weight-light">
                      Invalid APIs
                    </v-card-subtitle>
                    <v-card-text>
                      <template>
                        <v-simple-table v-if="api_status[0]" dense>
                          <template>
                            <thead>
                              <tr>
                                <th class="text-left font-weight-light">
                                  User
                                </th>
                                <th class="text-left font-weight-light">
                                  Timestamp
                                </th>
                                <!-- <th class="text-left font-weight-light">
                                  Message
                                </th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(status, i) in api_status" :key="i">
                                <td class="font-weight-light">
                                  {{ status.gsmg_user_id }}
                                </td>
                                <td class="font-weight-light">
                                  {{ status.timestamp }}
                                </td>
                                <!-- <td class="font-weight-light">
                                  {{ status.status_message }}
                                </td> -->
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <p v-else class="font-weight-light text-center ma-0">
                          No invalid APIs currently.
                        </p>
                      </template>
                    </v-card-text>
                  </v-card>
                  <v-card
                    class="mt-6"
                    :loading="!loaded_subscriptions_exceeding"
                  >
                    <v-card-subtitle class="pt-4 font-weight-light">
                      Subscriptions Exceeding
                    </v-card-subtitle>
                    <v-card-text>
                      <template>
                        <v-simple-table v-if="sub_exceeding_info[0]" dense>
                          <template>
                            <thead>
                              <tr>
                                <th class="text-left font-weight-light">
                                  User
                                </th>
                                <th class="text-left font-weight-light">
                                  Timestamp
                                </th>
                                <!-- <th class="text-left font-weight-light">
                                  Message
                                </th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(sub_exc, i) in sub_exceeding_info"
                                :key="i"
                              >
                                <td class="font-weight-light">
                                  {{ sub_exc.gsmg_user_id }} -
                                  {{ sub_exc.first_name }}
                                  {{ sub_exc.last_name }}
                                </td>
                                <td class="font-weight-light">
                                  {{ sub_exc.timestamp }}
                                </td>
                                <!-- <td class="font-weight-light">
                                  {{ sub_exc.status_message }}
                                </td> -->
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <p v-else class="font-weight-light text-center ma-0">
                          No managed value of a subscription exceeded.
                        </p>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="pa-1">
                <v-col cols="12">
                  <v-card :loading="!loaded_current_fee">
                    <v-card-text>
                      <template>
                        <template v-if="current_month_info[0]">
                          <v-data-table
                            dense
                            :headers="fee_table_headers"
                            :items="current_month_info"
                            :single-expand="true"
                            :expanded.sync="current_expanded"
                            item-key="gsmg_user_id"
                            show-expand
                            :items-per-page="20"
                            hide-default-footer
                            multi-sort
                            class="elevation-0 font-weight-light"
                          >
                            <template v-slot:expanded-item="{ headers, item }">
                              <td :colspan="headers.length">
                                <br />
                                <v-row>
                                  <v-col
                                    cols="6"
                                    v-for="(current_exchanges,
                                    i) in item.exchanges"
                                    :key="i"
                                  >
                                    <v-toolbar
                                      dense
                                      class="rounded-lg mb-2 elevation-1"
                                    >
                                      <v-app-bar-nav-icon disabled>
                                        <v-img
                                          v-if="
                                            current_exchanges.exchange_id == 1
                                          "
                                          class="ma-5"
                                          src="@/assets/Binance_logo.svg"
                                          contain
                                        ></v-img>
                                        <v-img
                                          v-else
                                          class="ma-5"
                                          src="@/assets/Bittrex_logo.svg"
                                          contain
                                        ></v-img>
                                      </v-app-bar-nav-icon>

                                      <v-toolbar-title
                                        class="font-weight-light"
                                        v-if="
                                          current_exchanges.exchange_id == 1
                                        "
                                      >
                                        Binance
                                      </v-toolbar-title>
                                      <v-toolbar-title
                                        class="font-weight-light"
                                        v-else
                                      >
                                        Bittrex
                                      </v-toolbar-title>
                                    </v-toolbar>

                                    <v-list>
                                      <v-list-item
                                        dense
                                        v-for="(current_quotes,
                                        j) in current_exchanges.quotes"
                                        :key="j"
                                        class="font-weight-light mp-1"
                                      >
                                        <v-list-item-content class="py-0">
                                          <v-row>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{
                                                  current_quotes.profit_quote
                                                }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                {{ current_quotes.quote_name }}
                                              </v-list-item-subtitle>
                                            </v-col>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{ current_quotes.profit_usd }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                USD
                                              </v-list-item-subtitle>
                                            </v-col>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{ current_quotes.profit_eur }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                EUR
                                              </v-list-item-subtitle>
                                            </v-col>
                                          </v-row>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-col>
                                </v-row>
                                <br />
                              </td>
                            </template>
                          </v-data-table>
                        </template>
                        <p v-else class="font-weight-light text-center ma-0">
                          No management fees info available.
                        </p>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="pa-1">
                <v-col cols="12">
                  <v-card :loading="!loaded_current_fee">
                    <v-card-text>
                      <template>
                        <template v-if="last_month_info[0]">
                          <v-data-table
                            dense
                            :headers="fee_table_headers"
                            :items="last_month_info"
                            :single-expand="true"
                            :expanded.sync="last_expanded"
                            item-key="gsmg_user_id"
                            show-expand
                            :items-per-page="20"
                            hide-default-footer
                            multi-sort
                            class="elevation-0 font-weight-light"
                          >
                            <template v-slot:expanded-item="{ headers, item }">
                              <td :colspan="headers.length">
                                <br />
                                <v-row>
                                  <v-col
                                    cols="6"
                                    v-for="(last_exchanges,
                                    i) in item.exchanges"
                                    :key="i"
                                  >
                                    <v-toolbar
                                      dense
                                      class="rounded-lg mb-2 elevation-1"
                                    >
                                      <v-app-bar-nav-icon disabled>
                                        <v-img
                                          v-if="last_exchanges.exchange_id == 1"
                                          class="ma-5"
                                          src="@/assets/Binance_logo.svg"
                                          contain
                                        ></v-img>
                                        <v-img
                                          v-else
                                          class="ma-5"
                                          src="@/assets/Bittrex_logo.svg"
                                          contain
                                        ></v-img>
                                      </v-app-bar-nav-icon>

                                      <v-toolbar-title
                                        class="font-weight-light"
                                        v-if="last_exchanges.exchange_id == 1"
                                      >
                                        Binance
                                      </v-toolbar-title>
                                      <v-toolbar-title
                                        class="font-weight-light"
                                        v-else
                                      >
                                        Bittrex
                                      </v-toolbar-title>
                                    </v-toolbar>

                                    <v-list>
                                      <v-list-item
                                        dense
                                        v-for="(last_quotes,
                                        j) in last_exchanges.quotes"
                                        :key="j"
                                        class="font-weight-light mp-1"
                                      >
                                        <v-list-item-content class="py-0">
                                          <v-row>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{ last_quotes.profit_quote }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                {{ last_quotes.quote_name }}
                                              </v-list-item-subtitle>
                                            </v-col>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{ last_quotes.profit_usd }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                USD
                                              </v-list-item-subtitle>
                                            </v-col>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{ last_quotes.profit_eur }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                EUR
                                              </v-list-item-subtitle>
                                            </v-col>
                                          </v-row>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-col>
                                </v-row>
                                <br />
                              </td>
                            </template>
                          </v-data-table>
                        </template>
                        <p v-else class="font-weight-light text-center ma-0">
                          No management fees info available.
                        </p>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="pa-1">
                <v-col cols="12">
                  <v-card :loading="!loaded_current_fee">
                    <v-card-text>
                      <template>
                        <template v-if="second_to_last_month_info[0]">
                          <v-data-table
                            dense
                            :headers="fee_table_headers"
                            :items="second_to_last_month_info"
                            :single-expand="true"
                            :expanded.sync="second_to_last_expanded"
                            item-key="gsmg_user_id"
                            show-expand
                            :items-per-page="20"
                            hide-default-footer
                            multi-sort
                            class="elevation-0 font-weight-light"
                          >
                            <template v-slot:expanded-item="{ headers, item }">
                              <td :colspan="headers.length">
                                <br />
                                <v-row>
                                  <v-col
                                    cols="6"
                                    v-for="(second_to_last_exchanges,
                                    i) in item.exchanges"
                                    :key="i"
                                  >
                                    <v-toolbar
                                      dense
                                      class="rounded-lg mb-2 elevation-1"
                                    >
                                      <v-app-bar-nav-icon disabled>
                                        <v-img
                                          v-if="
                                            second_to_last_exchanges.exchange_id ==
                                              1
                                          "
                                          class="ma-5"
                                          src="@/assets/Binance_logo.svg"
                                          contain
                                        ></v-img>
                                        <v-img
                                          v-else
                                          class="ma-5"
                                          src="@/assets/Bittrex_logo.svg"
                                          contain
                                        ></v-img>
                                      </v-app-bar-nav-icon>

                                      <v-toolbar-title
                                        class="font-weight-light"
                                        v-if="
                                          second_to_last_exchanges.exchange_id ==
                                            1
                                        "
                                      >
                                        Binance
                                      </v-toolbar-title>
                                      <v-toolbar-title
                                        class="font-weight-light"
                                        v-else
                                      >
                                        Bittrex
                                      </v-toolbar-title>
                                    </v-toolbar>

                                    <v-list>
                                      <v-list-item
                                        dense
                                        v-for="(second_to_last_quotes,
                                        j) in second_to_last_exchanges.quotes"
                                        :key="j"
                                        class="font-weight-light mp-1"
                                      >
                                        <v-list-item-content class="py-0">
                                          <v-row>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{
                                                  second_to_last_quotes.profit_quote
                                                }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                {{
                                                  second_to_last_quotes.quote_name
                                                }}
                                              </v-list-item-subtitle>
                                            </v-col>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{
                                                  second_to_last_quotes.profit_usd
                                                }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                USD
                                              </v-list-item-subtitle>
                                            </v-col>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{
                                                  second_to_last_quotes.profit_eur
                                                }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                EUR
                                              </v-list-item-subtitle>
                                            </v-col>
                                          </v-row>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-col>
                                </v-row>
                                <br />
                              </td>
                            </template>
                          </v-data-table>
                        </template>
                        <p v-else class="font-weight-light text-center ma-0">
                          No management fees info available.
                        </p>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row class="pa-1">
                <v-col cols="12">
                  <v-card :loading="!loaded_all_fee">
                    <v-card-text>
                      <template>
                        <template v-if="all_info[0]">
                          <v-data-table
                            dense
                            :headers="fee_table_headers"
                            :items="all_info"
                            :single-expand="true"
                            :expanded.sync="expanded"
                            item-key="gsmg_user_id"
                            show-expand
                            :items-per-page="20"
                            hide-default-footer
                            multi-sort
                            class="elevation-0 font-weight-light"
                          >
                            <template v-slot:expanded-item="{ headers, item }">
                              <td :colspan="headers.length">
                                <br />
                                <v-row>
                                  <v-col
                                    cols="6"
                                    v-for="(exchanges, i) in item.exchanges"
                                    :key="i"
                                  >
                                    <v-toolbar
                                      dense
                                      class="rounded-lg mb-2 elevation-1"
                                    >
                                      <v-app-bar-nav-icon disabled>
                                        <v-img
                                          v-if="exchanges.exchange_id == 1"
                                          class="ma-5"
                                          src="@/assets/Binance_logo.svg"
                                          contain
                                        ></v-img>
                                        <v-img
                                          v-else
                                          class="ma-5"
                                          src="@/assets/Bittrex_logo.svg"
                                          contain
                                        ></v-img>
                                      </v-app-bar-nav-icon>

                                      <v-toolbar-title
                                        class="font-weight-light"
                                        v-if="exchanges.exchange_id == 1"
                                      >
                                        Binance
                                      </v-toolbar-title>
                                      <v-toolbar-title
                                        class="font-weight-light"
                                        v-else
                                      >
                                        Bittrex
                                      </v-toolbar-title>
                                    </v-toolbar>

                                    <v-list>
                                      <v-list-item
                                        dense
                                        v-for="(quotes, j) in exchanges.quotes"
                                        :key="j"
                                        class="font-weight-light mp-1"
                                      >
                                        <v-list-item-content class="py-0">
                                          <v-row>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{ quotes.profit_quote }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                {{ quotes.quote_name }}
                                              </v-list-item-subtitle>
                                            </v-col>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{ quotes.profit_usd }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                USD
                                              </v-list-item-subtitle>
                                            </v-col>
                                            <v-col>
                                              <v-list-item-title
                                                class="font-weight-light"
                                              >
                                                {{ quotes.profit_eur }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle
                                                class="font-weight-light"
                                              >
                                                EUR
                                              </v-list-item-subtitle>
                                            </v-col>
                                          </v-row>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-col>
                                </v-row>
                                <br />
                              </td>
                            </template>
                          </v-data-table>
                        </template>
                        <p v-else class="font-weight-light text-center ma-0">
                          No management fees info available.
                        </p>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </v-col>
    </v-row>
  </dashboard>
</template>

<script>
import dashboard from "./Dashboard.vue";
import axios from "axios";
export default {
  name: "Management_Dashboard",
  components: {
    dashboard,
  },
  data() {
    return {
      mgmt_tab: 0,
      fee_table_headers: [
        {
          text: "User",
          align: "start",
          value: "user",
        },
        { text: "USD", align: "end", value: "sum_profit_usd" },
        { text: "EUR", align: "end", value: "sum_profit_eur" },
      ],

      //Loading
      loaded_subscriptions: false,
      loaded_subscriptions_exceeding: false,
      loaded_exchanges: false,
      loaded_managed: false,
      loaded_current_fee: false,
      loaded_all_fee: false,

      //Subscriptions
      sub_info: [],
      sub_exceeding_info: [],

      //Exchanges
      api_status: [],

      //Managed Values
      managed_values_sum: [],

      //Current fee (past three months)
      current_month_info: [],
      current_month_sum_fiat: [],
      current_expanded: [],
      last_month_info: [],
      last_month_sum_fiat: [],
      last_expanded: [],
      second_to_last_month_info: [],
      second_to_last_month_sum_fiat: [],
      second_to_last_expanded: [],

      //All fee
      all_info: [],
      sum_fiat: [],
      expanded: [],
    };
  },
  mounted() {
    this.getSubscriptions();
    this.getSubscriptionsExceeding();
    this.getExchanges();
    this.getManaged();
    this.getCurrentFee();
    this.getAllFee();
    if (localStorage.getItem("dark_mode") == "false") {
      this.$vuetify.theme.dark = false;
    } else {
      this.$vuetify.theme.dark = true;
    }
  },
  methods: {
    async getSubscriptions() {
      let token = await this.$auth.getTokenSilently();

      await axios
        .get(
          process.env.VUE_APP_API_URL + "/api/mgmt_dashboard/subscriptions",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        .then((response) => {
          console.log(response);
          this.sub_info = response.data.sub_info;
          this.loaded_subscriptions = true;
        });
    },
    async getSubscriptionsExceeding() {
      let token = await this.$auth.getTokenSilently();

      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/mgmt_dashboard/subscription_exceeding",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        .then((response) => {
          console.log(response);
          this.sub_exceeding_info = response.data.sub_exceeding_info;
          this.loaded_subscriptions_exceeding = true;
        });
    },
    async getExchanges() {
      let token = await this.$auth.getTokenSilently();

      await axios
        .get(process.env.VUE_APP_API_URL + "/api/mgmt_dashboard/exchanges", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        .then((response) => {
          console.log(response);
          this.api_status = response.data.api_status;
          this.loaded_exchanges = true;
        });
    },
    async getManaged() {
      let token = await this.$auth.getTokenSilently();

      await axios
        .get(process.env.VUE_APP_API_URL + "/api/mgmt_dashboard/managed", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        .then((response) => {
          console.log(response);
          this.managed_values_sum = response.data.managed_values_sum;
          this.loaded_managed = true;
        });
    },
    async getCurrentFee() {
      let token = await this.$auth.getTokenSilently();

      await axios
        .get(process.env.VUE_APP_API_URL + "/api/mgmt_dashboard/current_fee", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        .then((response) => {
          console.log(response);
          this.current_month_info = response.data.three_month_info[0];
          this.current_month_sum_fiat[0] =
            response.data.three_month_sum_fiat[0];
          this.last_month_info = response.data.three_month_info[1];
          this.last_month_sum_fiat[0] = response.data.three_month_sum_fiat[1];
          this.second_to_last_month_info = response.data.three_month_info[2];
          this.second_to_last_month_sum_fiat[0] =
            response.data.three_month_sum_fiat[2];
          this.loaded_current_fee = true;
        });
    },
    async getAllFee() {
      let token = await this.$auth.getTokenSilently();

      await axios
        .get(process.env.VUE_APP_API_URL + "/api/mgmt_dashboard/all_fee", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        .then((response) => {
          console.log(response);
          this.all_info = response.data.all_info;
          this.sum_fiat = response.data.sum_fiat;
          this.loaded_all_fee = true;
        });
    },
  },
};
</script>

<style>
.v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
.v-icon.v-icon::after {
  content: none !important;
}

.v-tabs {
  width: none !important;
}
.v-tabs-bar {
  height: 46px !important;
}
.theme--dark#main_tab_items,
.theme--dark.v-tabs > .v-tabs-bar {
  background-color: #121212 !important;
}
</style>
