<template>
  <div>
    <canvas :id="chart_id"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "DoughnutChart",
  props: ["config", "chart_id"],
  data() {
    return {};
  },
  watch: {
    config() {
      const ctx = document.getElementById(this.chart_id);
      new Chart(ctx, this.config);
    },
  },
  mounted() {
    const ctx = document.getElementById(this.chart_id);
    new Chart(ctx, this.config);
  },
};
</script>
