<template>
  <dashboard v-cloak>
    <v-row>
      <v-col cols="12">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined dark v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>{{ selected_pool.gsmg_user_id }} -
              {{ selected_pool.pool_apollo_email }}
            </v-btn>
          </template>

          <v-list style="max-height: 300px" class="overflow-y-auto">
            <v-list-item
              v-for="(item, i) in pools"
              :key="i"
              @click="setPool(item)"
            >
              <v-list-item-title
                >{{ item.gsmg_user_id }} -
                {{ item.pool_apollo_email }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="rounded-lg" :loading="!loaded_graph1">
          <v-card-text>
            <span class="text-h5 mb-10"
              >Estimated account value
              <!-- <v-btn icon @click="estimated_dialog = true">
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn> -->
            </span>

            <v-container>
              <v-row>
                <v-col
                  sm="12"
                  md="12"
                  lg="6"
                  v-for="exchange in exchange_values"
                  v-bind:key="exchange.exchange"
                >
                  <v-toolbar class="rounded-lg mb-2 elevation-3">
                    <v-app-bar-nav-icon disabled>
                      <v-img
                        v-if="exchange.exchange_id == 1"
                        class="ma-2"
                        src="@/assets/Binance_logo.svg"
                        contain
                      ></v-img>
                      <v-img
                        v-else
                        class="ma-2"
                        src="@/assets/Bittrex_logo.svg"
                        contain
                      ></v-img>
                    </v-app-bar-nav-icon>

                    <v-toolbar-title>
                      {{ exchange.exchange }}
                    </v-toolbar-title>
                  </v-toolbar>

                  <v-list outlined align="left" class="rounded-lg elevation-3">
                    <v-list-item class="lighten-4">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ exchange.account_btc.toFixed(8) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          BTC
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ exchange.account_eur.toFixed(2) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          EUR
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ exchange.account_usd.toFixed(2) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          USD
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="6">
        <v-card class="rounded-lg" :loading="!loaded_graph1">
          <v-card-text>
            <span class="text-h5 mb-10">Total account value </span>
            <v-container fluid>
              <Chart
                v-bind:config="total_acc_graph_data"
                v-bind:chart_id="'total-acc-value'"
                v-if="loaded_graph1"
              />
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="6">
        <v-card class="rounded-lg" :loading="!loaded_graph1">
          <v-card-text>
            <span class="text-h5 mb-10"
              >Completed trade pairs last 7 days
            </span>
            <Chart
              v-bind:config="total_trades_graph"
              v-bind:chart_id="'compl-trade-pairs'"
              v-if="loaded_graph2"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="rounded-lg" :loading="!loaded_graph1">
          <v-card-text>
            <div class="pb-7">
              <span class="text-h5"
                >Fractional account values for markets with same base currency
                <!-- <v-btn icon @click="helpDialog = true">
                  <v-icon>mdi-help-circle-outline</v-icon></v-btn
                > -->
              </span>
            </div>

            <v-row>
              <v-col
                md="6"
                sm="12"
                xs="12"
                v-for="quote in quote_graphs"
                :key="quote.quote"
              >
                <v-card class="rounded-lg elevation-8">
                  <v-card-text>
                    <div class="mb-10">
                      <span class="text-h6 ">{{ quote.quote }}</span>
                    </div>
                    <Chart
                      v-bind:config="quote.graph_conf"
                      v-bind:chart_id="quote.quote"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" v-if="exchange_profits">
        <v-tabs
          v-model="exchange_tab"
          @change="quote_tab = 0"
          background-color="transparent"
        >
          <v-tabs-slider color="transparent"></v-tabs-slider>
          <v-tab
            class="mr-2 rounded-t-lg"
            v-bind:class="[
              $vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4',
            ]"
            v-for="(exc, i) in exchange_profits"
            :key="i"
            :href="'#' + i"
          >
            <v-img
              class="mr-2"
              src="@/assets/Binance_logo.svg"
              contain
              v-if="i == 'Binance'"
            ></v-img>
            <v-img
              class="mr-2"
              src="@/assets/Bittrex_logo.svg"
              contain
              v-else
            ></v-img>
            {{ i }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="exchange_tab">
          <v-tab-item :value="exchange_tab">
            <v-card
              v-bind:class="[
                $vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4',
              ]"
            >
              <v-card-text>
                <span class="text-h4 mb-10 centered-content">Profits </span>
              </v-card-text>
              <v-tabs
                v-model="quote_tab"
                centered
                :background-color="
                  $vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'
                "
              >
                <v-tab
                  v-for="(quotes, j) in exchange_profits[exchange_tab]"
                  :key="j"
                  :href="'#' + j"
                >
                  {{ j }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="quote_tab">
                <v-tab-item :value="quote_tab">
                  <v-card
                    v-if="quote_tab"
                    v-bind:class="[
                      $vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4',
                    ]"
                  >
                    <v-card-text>
                      <v-row>
                        <v-col md="4" sm="12" xs="12" class="pa-10">
                          <v-row>
                            <v-col cols="12" class="pa-1">
                              <span class="text-h5 centered-content"
                                >{{ quote_tab }}
                              </span>
                            </v-col>
                            <v-col cols="12" class="pa-1 centered-content"
                              ><div>
                                <span class="text-h2"
                                  >{{
                                    exchange_profits[exchange_tab][quote_tab][
                                      "7"
                                    ]
                                      ? exchange_profits[exchange_tab][
                                          quote_tab
                                        ]["7"].profit.toFixed(5)
                                      : 0
                                  }} </span
                                ><span class="left-content" align="end"
                                  >USD
                                  {{
                                    exchange_profits[exchange_tab][quote_tab][
                                      "7"
                                    ]
                                      ? exchange_profits[exchange_tab][
                                          quote_tab
                                        ]["7"].profit_usd.toFixed(0)
                                      : 0
                                  }}
                                  <!-- <v-btn icon @click="usd_dialog = true"> -->
                                  <!-- <v-icon
                                      >mdi-help-circle-outline</v-icon
                                    ></v-btn
                                  > -->
                                </span>
                              </div>
                            </v-col>
                            <v-col cols="12" class="pa-1 "
                              ><span class="centered-content bottom">
                                Last 7 days profit</span
                              ></v-col
                            >
                          </v-row>
                        </v-col>
                        <v-col md="4" sm="12" xs="12" class="pa-10">
                          <v-row>
                            <v-col cols="12" class="pa-1">
                              <span class="text-h5 centered-content"
                                >{{ quote_tab }}
                              </span>
                            </v-col>
                            <v-col cols="12" class="pa-1 centered-content"
                              ><div>
                                <span class="text-h2"
                                  >{{
                                    exchange_profits[exchange_tab][quote_tab][
                                      "30"
                                    ]
                                      ? exchange_profits[exchange_tab][
                                          quote_tab
                                        ]["30"].profit.toFixed(5)
                                      : 0
                                  }} </span
                                ><span class="left-content" align="end"
                                  >USD
                                  {{
                                    exchange_profits[exchange_tab][quote_tab][
                                      "30"
                                    ]
                                      ? exchange_profits[exchange_tab][
                                          quote_tab
                                        ]["30"].profit_usd.toFixed(0)
                                      : 0
                                  }}
                                  <!-- <v-btn icon @click="usd_dialog = true">
                                    <v-icon
                                      >mdi-help-circle-outline</v-icon
                                    ></v-btn
                                  > -->
                                </span>
                              </div>
                            </v-col>
                            <v-col cols="12" class="pa-1 "
                              ><span class="centered-content bottom">
                                Last 30 days profit</span
                              ></v-col
                            >
                          </v-row>
                        </v-col>
                        <v-col md="4" sm="12" xs="12" class="pa-10">
                          <v-row>
                            <v-col cols="12" class="pa-1">
                              <span class="text-h5 centered-content">{{
                                quote_tab
                              }}</span>
                            </v-col>
                            <v-col cols="12" class="pa-1 centered-content"
                              ><div>
                                <span class="text-h2"
                                  >{{
                                    exchange_profits[exchange_tab][quote_tab][
                                      "alltime"
                                    ]
                                      ? exchange_profits[exchange_tab][
                                          quote_tab
                                        ]["alltime"].profit.toFixed(5)
                                      : 0
                                  }}
                                </span>
                              </div>
                            </v-col>
                            <v-col cols="12" class="pa-1 "
                              ><span class="centered-content bottom">
                                All time profit</span
                              ></v-col
                            >
                          </v-row>
                        </v-col>
                        <!-- <v-col cols="3" class="pa-10">
                          <v-row>
                            <v-col cols="12" class="pa-1">
                              <span class="text-h5 centered-content">BNB </span>
                            </v-col>
                            <v-col cols="12" class="pa-1 centered-content"
                              ><div>
                                <span class="text-h2">0.07002 </span
                                ><span class="left-content" align="end"
                                  >USD 29</span
                                >
                              </div>
                            </v-col>
                            <v-col cols="12" class="pa-1 "
                              ><span class="centered-content bottom">
                                Unrealised loss<v-btn
                                  icon
                                  @click="loss_dialog = true"
                                >
                                  <v-icon
                                    >mdi-help-circle-outline</v-icon
                                  ></v-btn
                                ></span
                              ></v-col
                            >
                          </v-row>
                        </v-col> -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-dialog v-model="estimated_dialog" width="500">
      <v-card light>
        <v-card-title class="text-h5 ">
          Estimated account value
        </v-card-title>

        <v-card-text>
          <p>
            As the name indicates, this is an estimate of the value of your spot
            wallet at the exchange. Since it is only an estimate, it is given
            with limited precision.
          </p>
          <p>
            The estimate is shown in at least USD and BTC. If the trade engine
            is active on markets with other base currencies, like ETH, then the
            estimate is also shown in those currencies.
          </p>
          <p>
            Note that the estimate may be as old as the last time the trade
            engine status was updated (see "Trade engine status"), which means
            the applied conversion rates may no longer be accurate (this is
            crypto after all).
          </p>
          <p>
            Also, in rare cases, it may happen that some odd coin in your wallet
            could not be converted to USD. In that case the trade engine may use
            the conversion rate to USDT instead. If that fails as well, the
            funds will not be included in the estimate.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" @click="estimated_dialog = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loss_dialog" width="500">
      <v-card light>
        <v-card-title class="text-h5 ">
          Unrealised loss
        </v-card-title>

        <v-card-text>
          <p>
            The "Unrealised loss" is the estimated difference between the
            original cost of acquired funds and their current worth. It is
            "unrealised", because as long as you don't sell at the current
            price, there is no loss realised. It is normal to have some
            unrealised loss, as the trade engine is waiting to sell the acquired
            funds for profit.
          </p>
          <p>
            The unrealised loss may well be negative. This means there is an
            unrealised profit. This happens if your acquired funds are worth
            more now than when they were acquired.
          </p>
          <p>
            Note that the unrealised loss is calculated from the current buy
            order history and the trade engine may not have checked whether the
            funds still exist in your wallet. This may happen if you have
            deselected a market where some acquired funds were not yet sold.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" @click="loss_dialog = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="usd_dialog" width="500">
      <v-card light>
        <v-card-title class="text-h5 ">
          Profit worth (USD)
        </v-card-title>

        <v-card-text>
          <p>
            The "Profit worth (USD)" shows the estimated value of the trade
            profit worth in USD. The conversion to USD was done using the
            exchange rate that was current at the moment of processing the
            filled sell order by the trade engine. That is usually less than 30
            minutes after the sell order was actually filled, but it still an
            estimate.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" @click="usd_dialog = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </dashboard>
</template>
<script>
import axios from "axios";
import dashboard from "./Dashboard.vue";
import Chart from "../components/chart.vue";

export default {
  components: {
    dashboard,
    Chart,
  },
  data() {
    return {
      exchange_tab: 0,
      active_exchange: "",
      active_quote: "",
      quote_tab: 0,
      estimated_dialog: false,
      loss_dialog: false,
      usd_dialog: false,
      total_trades_graph: {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: "Trades",
              fill: false,
              data: [],
              borderColor: "#D2B07F",
              backgroundColor: "#D2B07F",
              yAxisID: "y",
            },
            {
              label: "Trade profits (USD)",
              fill: false,
              data: [],
              borderColor: "#001D2C",
              backgroundColor: "#001D2C",
              yAxisID: "y1",
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Chart.js Line Chart",
            },
          },
          scales: {
            yAxes: [
              {
                id: "y",
                type: "linear",
                position: "left",
              },
              {
                id: "y1",
                type: "linear",
                position: "right",
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value) {
                        return   value + '$';
                    }
                }
              },
            ],
          },
        },
      },
      quote_graphs: [],
      total_acc_graph_data: {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: "BTC Account",
              fill: false,
              data: [],
              borderColor: "#D2B07F",
              backgroundColor: "#D2B07F",
              yAxisID: "y1",
            },
            {
              label: "USD Account",
              fill: false,
              data: [],
              borderColor: "#001D2C",
              backgroundColor: "#001D2C",
              yAxisID: "y",
            },
          ],
        },
        options: {
          responsive: true,
          interaction: {
            mode: "index",
            intersect: false,
          },
          stacked: false,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Chart.js Line Chart - Multi Axis",
            },
          },
          scales: {
            yAxes: [
              {
                id: "y",
                type: "linear",
                position: "left",
                 ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value) {
                        return   value + '$';
                    }
                }
              },
              {
                id: "y1",
                type: "linear",
                position: "right",
              },
            ],
          },
        },
      },
      date: new Date().toISOString().substr(0, 10),
      exchange_values: 0,
      exchange_profits: 0,
      loaded_graph1: false,
      loaded_graph2: false,
      pools: {},
      selected_pool: {
          gsmg_user_id: 0,
          pool_id: 0,
          pool_apollo_email: ""
      }
    };
  },
  computed: {},
  mounted() {
    this.getPools();
  },
  methods: {
      async getPools(){
          let token = await this.$auth.getTokenSilently();
          await axios
        .get(process.env.VUE_APP_API_URL + "/api/pool_dashboard/pools", {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        })
        .then((response) => {
          this.pools = response.data.pools;
        });
      },
      setPool(p){
          this.selected_pool.gsmg_user_id = p.gsmg_user_id;
          this.selected_pool.pool_id = p.pool_id;
          this.selected_pool.pool_apollo_email = p.pool_apollo_email;
          this.getDashboardData();
      },
    async getDashboardData() {
      let token = await this.$auth.getTokenSilently();

      await axios
        .get(process.env.VUE_APP_API_URL + "/api/pool_dashboard?pool=" + this.selected_pool.pool_id, {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        })
        .then((response) => {
          this.exchange_values = response.data.account_values;
          this.exchange_profits = response.data.exchange_profits;
          this.setAccValueGraph(response.data.account_values_graph);
          this.setTotalTradeGraph(response.data.total_trades_graph);
          this.setQuoteGraphs(response.data.segmented_values_graph);
        });
    },
    setTotalTradeGraph(trades) {
      let total_trades = [];
      let profits = [];
      for (let t in trades) {
        this.total_trades_graph.data.labels.indexOf(trades[t].date) === -1
          ? this.total_trades_graph.data.labels.push(trades[t].date)
          : 0;

        total_trades.push(trades[t].trades);
        profits.push(trades[t].sum_usd);
      }
      this.total_trades_graph.data.datasets[0].data = total_trades;
      this.total_trades_graph.data.datasets[1].data = profits;
      this.loaded_graph2 = true;
    },
    setAccValueGraph(account_values_graph) {
      let usd_account = [];
      let btc_account = [];
this.total_acc_graph_data.data.datasets[0].data = [];
this.total_acc_graph_data.data.datasets[1].data = [];
      for (let v in account_values_graph) {
        // lables x-axis
        this.total_acc_graph_data.data.labels.indexOf(
          account_values_graph[v].tag
        ) === -1
          ? this.total_acc_graph_data.data.labels.push(
              account_values_graph[v].tag
            )
          : 0;
          if(account_values_graph[v].account_btc &&account_values_graph[v].account_usd){
        btc_account.push(account_values_graph[v].account_btc.toFixed(5));
        usd_account.push(account_values_graph[v].account_usd.toFixed(2));}else{
          btc_account.push(0);
        usd_account.push(0);
        }
      }

      this.total_acc_graph_data.data.datasets[0].data = btc_account;
      this.total_acc_graph_data.data.datasets[1].data = usd_account;

      this.loaded_graph1 = true;
    },
    setQuoteGraphs(segmented_values_graph) {
    this.quote_graphs = [];
      for (let svg in segmented_values_graph) {
        let quote_graph = {};
        quote_graph.quote = svg;
        let days = [];
        let values = [];
        for(let q in segmented_values_graph.[svg] ){
          days.push(segmented_values_graph.[svg].[q].day);
          values.push(segmented_values_graph.[svg].[q].quote_value.toFixed(4));
        }
        let graph_conf = {
        type: "line",
        data: {
          labels: days,
          datasets: [
            {
              label: quote_graph.quote,
              fill: false,
              data: values,
              borderColor: "#D2B07F",
              backgroundColor: "#D2B07F",
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Chart.js Line Chart",
            },
          },
        },
      };
      quote_graph.graph_conf = graph_conf;
      this.quote_graphs.push(quote_graph);
      }
    },
  },
};
</script>

<style scoped>
.v-tab:hover {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
</style>
