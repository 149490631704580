<template>
  <dashboard v-cloak v-slot:default="slotProps">
    <!-- Kompletter Hack, pfusch des jahrhunderts -->
    <span hidden>{{ (selected_user_id = slotProps.user) }}</span>
    <h1>Daily view</h1>
    <v-row>
      <v-col cols="4">
        <v-card outlined elevation="3">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-row>
                      <v-btn-toggle class="date_button" rounded>
                        <v-btn @click="minusDay()" rounded>
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        <v-btn @click="plusDay()" rounded>
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-btn-toggle>

                      <v-text-field
                        v-model="date"
                        label="Select Day"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        append-icon="mdi-chevron-triple-right"
                        @click:append="
                          date = new Date().toISOString().substr(0, 10)
                        "
                      ></v-text-field>
                    </v-row>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="account_values.length + (account_values.length + 1)">
        <h2>Exchange Values</h2>
        <v-card outlined elevation="3">
          <v-card-text>
            <v-row>
              <template v-if="account_values">
                <v-col
                  :cols="12 / account_values.length"
                  v-for="exc in account_values"
                  :key="exc.exchange"
                >
                  <v-card elevation="3">
                    <v-toolbar class="toolbar" rounded="t-sm">
                      <v-toolbar-title class="white--text">
                        {{ exc.exchange }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-list align="right">
                      <v-list-item class="lighten-4" v-if="exc.account_btc">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ exc.account_btc.toFixed(5) }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            BTC
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="divider" />
                      <v-list-item v-if="exc.managed_btc">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ exc.managed_btc.toFixed(5) }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            BTC Managed
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="divider" />
                      <v-list-item v-if="exc.account_usd">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ exc.account_usd.toFixed(2) }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            USD
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="divider" />
                      <v-list-item v-if="exc.managed_usd">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ exc.managed_usd.toFixed(2) }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            USD Managed
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <h2>Overall Statistics</h2>
        <v-card outlined elevation="3">
          <v-card-text>
            <v-row>
              <template v-if="stats[0]">
                <v-col cols="6">
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          BTC Account Value
                        </v-list-item-subtitle>
                        <v-list-item-title align="right">
                          {{ stats[0].sum_account_value_btc.toFixed(8) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="divider" v-if="stats[0].sum_profit_btc" />
                    <v-list-item v-if="stats[0].sum_profit_btc">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          BTC Profit
                        </v-list-item-subtitle>
                        <v-list-item-title align="right">
                          {{ stats[0].sum_profit_btc.toFixed(8) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="divider" v-if="stats[0].sum_profit_eur" />
                    <v-list-item v-if="stats[0].sum_profit_eur">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          EUR Profit
                        </v-list-item-subtitle>
                        <v-list-item-title align="right">
                          {{ stats[0].sum_profit_eur.toFixed(2) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      class="divider"
                      v-if="stats[0].percent_return_on_account > 0"
                    />
                    <v-list-item v-if="stats[0].percent_return_on_account > 0">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          % Return on Account
                        </v-list-item-subtitle>
                        <v-list-item-title align="right">
                          {{ stats[0].percent_return_on_account.toFixed(2) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="divider" />
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          Trades
                        </v-list-item-subtitle>
                        <v-list-item-title align="right">
                          {{ stats[0].trades }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="6">
                  <v-list>
                    <v-list-item v-if="stats[0].sum_avg_managed_btc">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          BTC Managed
                        </v-list-item-subtitle>
                        <v-list-item-title align="right">
                          {{ stats[0].sum_avg_managed_btc.toFixed(8) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="divider" v-if="stats[0].sum_profit_eth" />
                    <v-list-item v-if="stats[0].sum_profit_eth">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          ETH Profit
                        </v-list-item-subtitle>
                        <v-list-item-title align="right">
                          {{ stats[0].sum_profit_eth.toFixed(8) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="divider" v-if="stats[0].sum_profit_usd" />
                    <v-list-item v-if="stats[0].sum_profit_usd">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          USD Profit
                        </v-list-item-subtitle>
                        <v-list-item-title align="right">
                          {{ stats[0].sum_profit_usd.toFixed(2) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="divider" v-if="stats[0].avg_profit" />
                    <v-list-item v-if="stats[0].avg_profit">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          Avg % Profit / Trade
                        </v-list-item-subtitle>
                        <v-list-item-title align="right">
                          {{ stats[0].avg_profit.toFixed(2) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="divider" />
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          Sells
                        </v-list-item-subtitle>
                        <v-list-item-title align="right">
                          {{ stats[0].sells }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <!-- <v-col cols="4">
                  <v-text-field
                    label="MAX Profit"
                    :value="stats[0].max_profit.toFixed(2)"
                    suffix="%"
                    readonly
                  ></v-text-field>
                </v-col> -->
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2>Quote Profits</h2>
        <v-card outlined elevation="3">
          <v-card-text>
            <v-row>
              <v-col
                cols="4"
                v-for="profit in quote_profit"
                :key="profit.quote"
              >
                <v-card elevation="3">
                  <template v-if="profit.profit">
                    <v-toolbar class="toolbar centered-content" rounded="t-sm">
                      <v-toolbar-title class="white--text">
                        {{ profit.quote }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-row class="mb-0">
                      <v-col cols="6" class="pb-0 pr-0">
                        <v-list align="right">
                          <v-list-item v-if="profit.profit">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ profit.profit.toFixed(8) }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Quote Profit
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="divider" v-if="profit.profit_btc" />
                          <v-list-item v-if="profit.profit_btc">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ profit.profit_btc.toFixed(8) }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                BTC Profit
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="divider" v-if="profit.profit_eur" />
                          <v-list-item v-if="profit.profit_eur">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ profit.profit_eur.toFixed(2) }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                EUR Profit
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="divider" v-if="profit.trades > 0" />
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ profit.trades }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Trades
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="6" class="pb-0 pl-0">
                        <v-list align="right">
                          <v-list-item v-if="profit.return_on_quote">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ profit.return_on_quote.toFixed(5) }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                % Return on Quote
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="divider" v-if="profit.profit_eth" />
                          <v-list-item v-if="profit.profit_eth">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ profit.profit_eth.toFixed(8) }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                ETH Profit
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="divider" v-if="profit.profit_usd" />
                          <v-list-item v-if="profit.profit_usd">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ profit.profit_usd.toFixed(2) }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                USD Profit
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider
                            class="divider"
                            v-if="profit.sells_quote > 0"
                          />
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ profit.sells_quote }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Sells
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <h2>Market Pairs</h2>
        <v-card outlined elevation="3">
          <v-card-text>
            <v-tabs v-model="tab">
              <v-tab>
                <h4>Table</h4>
              </v-tab>
              <v-tab>
                <h4>Charts</h4>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      :headers="headers_pairs"
                      :items="pairs"
                      :items-per-page="20"
                      class="elevation-1"
                    >
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <h3>Sells</h3>
                        <DoughnutChart
                          v-bind:config="config1"
                          v-bind:chart_id="'quote-sells'"
                        ></DoughnutChart>
                      </v-col>
                      <v-col cols="6">
                        <h3>AVG Profit</h3>
                        <DoughnutChart
                          v-bind:config="config2"
                          v-bind:chart_id="'quote-sells2'"
                        ></DoughnutChart>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <h2>Closed Trades</h2>
        <v-card outlined elevation="3">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="orders"
              :items-per-page="20"
              class="elevation-1"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </dashboard>
</template>

<script>
import dashboard from "./Dashboard.vue";
import DoughnutChart from "../components/chart.vue";

import axios from "axios";
export default {
  name: "Daily",
  components: {
    dashboard,
    DoughnutChart,
  },
  data() {
    return {
      config1: {
        type: "doughnut",
        data: {
          labels: [],
          datasets: [
            {
              label: "Pairs",
              data: [],
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 205, 86)",
                "rgb(22, 99, 132)",
                "rgb(154, 162, 235)",
                "rgb(55, 205, 86)",
                "rgb(15, 99, 132)",
                "rgb(244, 162, 235)",
                "rgb(12, 205, 86)",
                "rgb(255, 5, 132)",
                "rgb(54, 6, 235)",
                "rgb(255, 335, 86)",
                "rgb(255, 9, 22)",
                "rgb(54, 162, 78)",
                "rgb(255, 205,16)",
                "rgb(255, 1, 132)",
                "rgb(14, 162, 235)",
                "rgb(255, 55, 86)",
                "rgb(255, 99, 1)",
                "rgb(54, 162, 30)",
                "rgb(335, 205, 86)",
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {},
        },
      },
      config2: {
        type: "bar",
        data: {
          labels: [],
          datasets: [
            {
              label: "Pairs",
              data: [],
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(54, 162, 235)",
                "rgb(255, 205, 86)",
                "rgb(22, 99, 132)",
                "rgb(154, 162, 235)",
                "rgb(55, 205, 86)",
                "rgb(15, 99, 132)",
                "rgb(244, 162, 235)",
                "rgb(12, 205, 86)",
                "rgb(255, 5, 132)",
                "rgb(54, 6, 235)",
                "rgb(255, 335, 86)",
                "rgb(255, 9, 22)",
                "rgb(54, 162, 78)",
                "rgb(255, 205,16)",
                "rgb(255, 1, 132)",
                "rgb(14, 162, 235)",
                "rgb(255, 55, 86)",
                "rgb(255, 99, 1)",
                "rgb(54, 162, 30)",
                "rgb(335, 205, 86)",
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      },
      headers: [
        { text: "Quote", value: "quote" },
        { text: "Base", value: "base" },
        { text: "Exchange", value: "exchange" },
        { text: "Profit", value: "profit" },
        { text: "Profit %", value: "profit_percentage" },
        { text: "Time", value: "timestamp" },
      ],
      headers_pairs: [
        { text: "Quote", value: "quote" },
        { text: "Base", value: "base" },
        { text: "Trades", value: "trades" },
        { text: "Sells", value: "sells" },
        { text: "Avg % / Trade", value: "avg_profit_percentage" },
        { text: "Profit Quote / Trade", value: "avg_quote_profit" },
        { text: "Sum Profit Quote", value: "sum_quote_profit" },
        { text: "Profit BTC / Trade", value: "avg_profit_btc" },
        { text: "Sum Profit BTC", value: "sum_profit_btc" },
        { text: "Sum Profit ETH", value: "sum_profit_eth" },
        { text: "Sum Profit USD", value: "sum_profit_usd" },
        { text: "Sum Profit EUR", value: "sum_profit_eur" },
      ],
      tab: "",
      orders: [],
      pairs: [],
      stats: [],
      account_values: [],
      selected_user_id: 0,
      quote_profit: [],
      menu: false,
      date: new Date().toISOString().substr(0, 10),
    };
  },
  mounted() {
    this.selected_user_id = localStorage.getItem("user_id");
  },
  watch: {
    date: function() {
      this.selected_user_id = localStorage.getItem("user_id");
      if (this.selected_user_id != 0) {
        this.getOrders();
      }
    },
    selected_user_id: function() {
      this.getOrders();
    },
  },
  methods: {
    async getOrders() {
      this.orders = [];
      this.pairs = [];
      this.stats = [];
      this.quote_profit = [];
      this.account_values = [];
      let token = await this.$auth.getTokenSilently();
      let aconfig = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/daily_orders?user_id=" +
            this.selected_user_id +
            "&date=" +
            this.date,
          aconfig
        )
        .then((response) => {
          this.orders = response.data.orders;
          this.pairs = response.data.base_pairs;
          this.stats = response.data.stats;
          this.account_values = response.data.account_values;
          this.quote_profit = response.data.quote_profit;
          this.buildChart();
        });
    },
    async buildChart() {
      let lab = [];
      let da = [];
      let profit = [];
      for (let i in this.pairs) {
        lab.push(this.pairs[i].quote + "-" + this.pairs[i].base);
        da.push(this.pairs[i].sells);
        profit.push(this.pairs[i].avg_profit_percentage);
      }

      this.config1.data.labels = lab;
      this.config2.data.labels = lab;
      this.config1.data.datasets[0].data = da;
      this.config2.data.datasets[0].data = profit;
    },
    plusDay() {
      let cDate = new Date(this.date);
      cDate = cDate.setDate(cDate.getDate() + 1);
      this.date = new Date(cDate).toISOString().substr(0, 10);
    },
    minusDay() {
      let cDate = new Date(this.date);
      cDate = cDate.setDate(cDate.getDate() - 1);
      this.date = new Date(cDate).toISOString().substr(0, 10);
    },
  },
};
</script>

<style>
.centered-content {
  justify-content: center !important;
  align-content: center !important;
  display: flex !important;
}
.date_button {
  margin-top: 10px;
  margin-right: 10px;
}
</style>
