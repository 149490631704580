import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: false,
  theme: {
    light: true,
    themes: {
      dark: {
        // primary: "#D9A050",
        primary: "#D2B07F",
        // accent: "#50A4D9",
        accent: "#D2B07F",
        // secondary: "#667680",
        secondary: "#D2B07F",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
        toolbar: "#001D2C",
        divider: "#D2B07F",
      },
      light: {
        // primary: "#1976D2",
        primary: "#D2B07F",
        // accent: "#e91e63",
        accent: "#D2B07F",
        // secondary: "#30B1DC",
        secondary: "#D2B07F",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
        toolbar: "#001D2C",
        divider: "#D2B07F",
      },
    },
  },
});
