<template>
  <dashboard>
    <v-row>
      <v-col cols="12">
        <h1>Poolmanagement</h1>
      </v-col>
      <v-col cols="12">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              dark
              v-bind="attrs"
              v-on="on"
              v-if="selected_pool.id != 0"
            >
              <v-icon>mdi-account</v-icon>{{ selected_pool.id }} -
              {{ selected_pool.mail }}
            </v-btn>
            <v-btn outlined dark v-bind="attrs" v-on="on" v-else>
              <v-icon>mdi-account</v-icon>Select Pool
            </v-btn>
          </template>

          <v-list style="max-height: 300px" class="overflow-y-auto">
            <v-list-item
              v-for="(item, i) in pools"
              :key="i"
              @click="setPool(item)"
            >
              <v-list-item-title
                >{{ item.pool_id }} -
                {{ item.pool_apollo_email }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text>
            <v-row class="text-subtitle-2">
              <v-col cols="2"> </v-col>
              <v-col cols="1">Exchange</v-col>
              <v-col cols="9">
                <v-row>
                  <v-col cols="2">Quote</v-col>
                  <v-col cols="2">Value</v-col>
                  <v-col cols="2">Share %</v-col>
                  <v-col cols="5" class="text-center">Change</v-col>
                  <v-col cols="1"></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card
          v-for="(item, key) in pool_user_shares"
          :key="key"
          outlined
          class="ma-1"
        >
          <v-card-text>
            <v-row v-for="(exc, lol) in item" :key="lol">
              <v-col cols="12" v-if="lol != 1"><v-divider></v-divider></v-col>
              <v-col cols="2" class="pt-5"
                ><span v-if="lol == 1">
                  {{ exc[0].first_name + " " + exc[0].last_name }}</span
                ></v-col
              >
              <v-col cols="1" class="pt-5">{{ exc[0].exchange }}</v-col>

              <v-col cols="9">
                <v-row
                  v-for="(quo, klo) in exc"
                  :key="klo"
                  class="justify-center align-center"
                >
                  <v-col cols="12" v-if="klo != 0"
                    ><v-divider></v-divider
                  ></v-col>
                  <v-col cols="2">
                    {{ quo.quote_name }}
                  </v-col>
                  <v-col cols="2">
                    {{ quo.quote_value }}
                  </v-col>
                  <v-col cols="2">
                    {{ quo.share_percentage }}
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      dense
                      hide-details
                      v-model.number="quo.change_value"
                      solo-inverted
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-btn @click="checkChange(quo)" dense color="primary">
                      SAVE
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-if="selected_pool.id != 0">
          <v-card-text>
            <v-row>
              <v-btn color="success" @click="newDialog = true" block>
                Add Pool-User
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="90%">
      <v-card>
        <v-card-title class="text-h5">
          Calculated Changes
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="4"
              v-for="(user, index) in calculated_user_values"
              :key="index"
            >
              <v-list align="left">
                <v-list-item class="lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      USER
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="divider" />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.exchange }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      exchange
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="divider" />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.quote_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Changed Quote
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="divider" />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="user.partial_quote_value == user.new_quote_value"
                    >
                      <span>{{ user.partial_quote_value }} </span>
                      <v-icon small class="pb-1 mx-3">mdi-arrow-right</v-icon>
                      <span> {{ user.new_quote_value }}</span>
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      <span>{{ user.partial_quote_value }} </span>
                      <v-icon small class="pb-1 mx-3">mdi-arrow-right</v-icon>
                      <span
                        v-bind:class="[
                          user.partial_quote_value < user.new_quote_value
                            ? 'green--text'
                            : 'red--text',
                        ]"
                      >
                        {{ user.new_quote_value }}
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      quote value change
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="divider" />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span>{{ user.share_percentage.toFixed(8) }} % </span
                      ><v-icon small class="pb-1 mx-3">mdi-arrow-right</v-icon>
                      <span
                        v-bind:class="[
                          user.share_percentage < user.new_share_percentage
                            ? 'green--text'
                            : 'red--text',
                        ]"
                        >{{ user.new_share_percentage }} %</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      quote percentage change
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" @click="dialog = false">
            Disagree
          </v-btn>
          <v-btn color="green darken-1" @click="saveChanges()">
            Agree
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newDialog" max-width="90%">
      <v-card>
        <v-card-title class="text-h5">
          Add new User
        </v-card-title>
        <v-card-text>
          <v-alert v-model="alert" dense border="left" type="warning">
            Quote on Exchange already selected. Please set value.
          </v-alert>
          <v-row>
            <v-col cols="12">
              <h3>Select User</h3>
            </v-col>
            <v-col cols="12">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined dark v-bind="attrs" v-on="on">
                    <v-icon>mdi-account</v-icon>{{ selected_user_id }} -
                    {{ selected_user_name }}
                  </v-btn>
                </template>

                <v-list style="max-height: 300px" class="overflow-y-auto">
                  <v-list-item
                    v-for="(item, i) in users"
                    :key="i"
                    @click="setUser(item)"
                  >
                    <v-list-item-title
                      >{{ item.name }} - {{ item.id }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <h3>Add Quotes</h3>
            </v-col>
            <v-col cols="5">
              <v-select
                v-if="quotes"
                :items="quotes"
                item-text="name"
                item-value="quote_id"
                v-model="selected_quote"
                filled
                label="Quotes"
              ></v-select>
            </v-col>
            <v-col cols="5">
              <v-select
                v-if="exchanges"
                :items="exchanges"
                item-text="name"
                item-value="exchange_id"
                v-model="selected_exchange"
                filled
                label="Exchanges"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mt-2"
                small
                fab
                dark
                color="success"
                @click="addQuote()"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
            <v-divider class="divider"> </v-divider>
            <v-col cols="12">
              <v-row>
                <v-col cols="4">
                  Exchange
                </v-col>
                <v-col cols="4">
                  Quote
                </v-col>
                <v-col cols="4">
                  Amount
                </v-col>
              </v-row>
              <v-row v-for="(item, i) in newUser.quotes" :key="i">
                <v-col cols="4">
                  {{ item.exchange_name }}
                </v-col>
                <v-col cols="4">
                  {{ item.quote_name }}
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    dense
                    hide-details
                    v-model.number="item.quote_amount"
                    solo-inverted
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-divider class="divider"> </v-divider>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" @click="calcNewUser()">
            CALC USER
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </dashboard>
</template>

<script>
import dashboard from "./Dashboard.vue";
import axios from "axios";
import percentRound from "percent-round";
export default {
  name: "Poolmanagement",
  components: {
    dashboard,
  },
  data() {
    return {
      loading: false,
      pools: [],
      dialog: false,
      newDialog: false,
      current_change: [],
      pool_user_shares: {},
      selected_pool: {
        mail: "",
        id: 0,
        user_id: 0,
      },
      calculated_user_values: [],
      users: [],
      newUser: {
        user_id: "",
        quotes: [],
      },
      selected_user_id: 0,
      selected_user_name: "",
      selected_quote: "",
      selected_exchange: "",
      quotes: [],
      exchanges: [],
      alert: false,
    };
  },
  mounted() {
    this.getPools();
    this.getUsers();
  },
  methods: {
    setPool(item) {
      this.selected_pool.mail = item.pool_apollo_email;
      this.selected_pool.id = item.pool_id;
      this.selected_pool.user_id = item.gsmg_user_id;
      this.getPoolShares();
    },
    async getPools() {
      let token = await this.$auth.getTokenSilently();
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/pool_management/pools", {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        })
        .then((response) => {
          this.pools = response.data.pools;
        });
    },
    async getPoolShares() {
      this.pool_user_shares = {};
      this.calculated_user_values = [];
      let token = await this.$auth.getTokenSilently();
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/pool_management/pool_user_shares?pool_id=" +
            this.selected_pool.id +
            "&user_id=" +
            this.selected_pool.user_id,
          {
            headers: {
              Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then((response) => {
          this.pool_user_shares = response.data.pool_user_shares;
        });
    },
    async checkChange(quote) {
      console.log(quote);
      this.current_change = quote;

      // GET USER PERCENTAGES FROM POOL QUOTE
      let token = await this.$auth.getTokenSilently();
      let datar = {
        exchange_id: quote.exchange_id,
        quote_id: quote.quote_id,
        pool_id: quote.pool_id,
        user_id: this.selected_pool.user_id,
      };
      let { data } = await axios.post(
        process.env.VUE_APP_API_URL +
          "/api/pool_management/pool_percentages_quote",
        datar,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      let pool_percentages_quote = data.pool_percentages_quote;
      let database_quote_value = data.current_quote_values[0].value;
      let database_quote_value_new = data.current_quote_values[0].value;
      // CALC NEW PERCENTAGES
      this.calculated_user_values = [];
      for (let i in pool_percentages_quote) {
        let uv = {};
        // Basic info
        uv.user_id = pool_percentages_quote[i].user_id;
        uv.name =
          pool_percentages_quote[i].first_name +
          " " +
          pool_percentages_quote[i].last_name;
        uv.quote_name = this.current_change.quote_name;
        uv.exchange = this.current_change.exchange;

        uv.partial_quote_value =
          (pool_percentages_quote[i].share_percentage / 100) *
          database_quote_value;
        uv.share_percentage = pool_percentages_quote[i].share_percentage;

        if (pool_percentages_quote[i].user_id == this.current_change.user_id) {
          // NEW OVERALL QUOTE VALUE
          database_quote_value_new += this.current_change.change_value;

          uv.new_quote_value =
            uv.partial_quote_value + this.current_change.change_value;
        } else {
          uv.new_quote_value = uv.partial_quote_value;
        }
        uv.exchange_id = quote.exchange_id;
        uv.quote_id = quote.quote_id;
        uv.pool_id = quote.pool_id;
        this.calculated_user_values.push(uv);
      }
      // FIX UNALLOCATED QUOTE VALUE
      let smallest_quote_value = 0;
      let smallest_acc = {};
      let seg_sum = 0;
      for (let cuv in this.calculated_user_values) {
        if (
          smallest_quote_value == 0 ||
          smallest_quote_value >
            this.calculated_user_values[cuv].new_quote_value
        ) {
          smallest_quote_value = this.calculated_user_values[cuv]
            .new_quote_value;
          smallest_acc = this.calculated_user_values[cuv];
        }
        seg_sum += this.calculated_user_values[cuv].new_quote_value;
      }
      let quote_diff = database_quote_value_new - seg_sum;

      smallest_acc.new_quote_value = smallest_acc.new_quote_value + quote_diff;

      //CALC NEW PERCENTAGES
      let percentages = [];
      for (let cuv in this.calculated_user_values) {
        // new percent values
        // IF smallest acc == cuv use new quote value from smalles acc
        if (database_quote_value_new == 0) {
          this.calculated_user_values[cuv].new_share_percentage = 0;
        } else if (
          this.calculated_user_values[cuv].user_id == smallest_acc.user_id
        ) {
          this.calculated_user_values[cuv].new_share_percentage =
            (smallest_acc.new_quote_value / database_quote_value_new) * 100;
        } else {
          this.calculated_user_values[cuv].new_share_percentage =
            (this.calculated_user_values[cuv].new_quote_value /
              database_quote_value_new) *
            100;
        }

        // Store Percentages to array
        percentages.push(this.calculated_user_values[cuv].new_share_percentage);

        this.calculated_user_values[
          cuv
        ].database_quote_value_new = database_quote_value_new;

        this.calculated_user_values[
          cuv
        ].database_quote_value = database_quote_value;
      }
      percentages = percentRound(percentages, 16);

      for (let cuv in this.calculated_user_values) {
        this.calculated_user_values[cuv].new_share_percentage =
          percentages[cuv];
      }

      this.dialog = true;
    },

    async saveChanges() {
      this.loading = true;
      console.log(this.calculated_user_values);
      let token = await this.$auth.getTokenSilently();
      let datar = {
        changed_pool: this.calculated_user_values,
      };

      let { data } = await axios.post(
        process.env.VUE_APP_API_URL + "/api/pool_management/save_changes",
        datar,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      if (data == true) {
        this.loading = false;
        this.getPoolShares();
      }
      this.dialog = false;
    },
    async getUsers() {
      let token = await this.$auth.getTokenSilently();
      let aconfig = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/users", aconfig)
        .then((response) => {
          for (let u in response.data.users) {
            let us = {};
            us.name =
              response.data.users[u].first_name +
              " " +
              response.data.users[u].last_name;
            us.id = "" + response.data.users[u].gsmg_user_id + "";
            us.apollo_id = response.data.users[u].user_id;
            this.users.push(us);
          }
        });
      await axios
        .get(
          process.env.VUE_APP_API_URL + "/api/pool_management/quotes_exchanges",
          aconfig
        )
        .then((response) => {
          this.quotes = response.data.quotes;
          this.exchanges = response.data.exchanges;
        });
    },
    setUser(user) {
      this.selected_user_name = user.name;
      this.selected_user_id = user.id;
      this.newUser.user_id = user.id;
      this.newUser.ap_id = user.apollo_id;
    },
    addQuote() {
      this.alert = false;
      if (
        this.newUser.quotes.find(
          (e) =>
            e.quote_id == this.selected_quote &&
            e.exchange_id == this.selected_exchange
        )
      ) {
        this.alert = true;
        return;
      }

      let quote_name = this.quotes.find(
        (e) => e.quote_id == this.selected_quote
      );
      let exchange_name = this.exchanges.find(
        (e) => e.exchange_id == this.selected_exchange
      );

      this.newUser.quotes.push({
        quote_id: this.selected_quote,
        quote_name: quote_name.name,
        exchange_id: this.selected_exchange,
        exchange_name: exchange_name.name,
        quote_amount: 0,
      });
    },
    async calcNewUser() {
      // GET USER PERCENTAGES FROM POOL QUOTE
      let quote_results = [];
      for (let nq of this.newUser.quotes) {
        let token = await this.$auth.getTokenSilently();
        let datar = {
          quotes: nq,
          pool_id: this.selected_pool.id,
          user_id: this.newUser.ap_id,
          gsmg_user_id: this.selected_pool.user_id,
        };
        let { data } = await axios.post(
          process.env.VUE_APP_API_URL +
            "/api/pool_management/pool_percentages_quote_multiple",
          datar,
          {
            headers: {
              Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
            },
          }
        );
        this.current_change = {
          change_value: nq.quote_amount,
          exchange: nq.exchange_name,
          exchange_id: nq.exchange_id,
          first_name: "Christian",
          last_name: "Eckl",
          pool_id: this.selected_pool.id,
          pool_share_id: null,
          quote_id: nq.quote_id,
          quote_name: nq.quote_name,
          quote_value: 0,
          share_percentage: 0,
          user_id: this.newUser.ap_id,
        };
        data.result[0].pool_percentages_quote.push({
          exchange_id: nq.exchange_id,
          name: this.selected_user_name,
          pool_id: this.selected_pool.id,
          pool_share_id: null,
          quote_id: nq.quote_id,
          share_percentage: 0,
          user_id: this.newUser.ap_id,
        });

        this.calculated_user_values = [];
        console.log(data.result);
        for (let d in data.result) {
          let pool_percentages_quote = data.result[d].pool_percentages_quote;
          let database_quote_value =
            data.result[d].current_quote_values[0].value;
          let database_quote_value_new =
            data.result[d].current_quote_values[0].value;

          // CALC NEW PERCENTAGES
          for (let i in pool_percentages_quote) {
            console.log(pool_percentages_quote[i]);
            let uv = {};
            // Basic info
            uv.user_id = pool_percentages_quote[i].user_id;
            if (pool_percentages_quote[i].first_name) {
              uv.name =
                pool_percentages_quote[i].first_name +
                " " +
                pool_percentages_quote[i].last_name;
            } else {
              uv.name = pool_percentages_quote[i].name;
            }
            uv.quote_name = this.current_change.quote_name;
            uv.exchange = this.current_change.exchange;

            uv.partial_quote_value =
              (pool_percentages_quote[i].share_percentage / 100) *
              database_quote_value;
            uv.share_percentage = pool_percentages_quote[i].share_percentage;

            if (
              pool_percentages_quote[i].user_id == this.current_change.user_id
            ) {
              // NEW OVERALL QUOTE VALUE
              database_quote_value_new += this.current_change.change_value;

              uv.new_quote_value =
                uv.partial_quote_value + this.current_change.change_value;
            } else {
              uv.new_quote_value = uv.partial_quote_value;
            }
            uv.exchange_id = data.result[d].current_quote_values[0].exchange_id;
            uv.quote_id = data.result[d].current_quote_values[0].quote_id;
            uv.pool_id = this.selected_pool.id;
            this.calculated_user_values.push(uv);
          }

          // FIX UNALLOCATED QUOTE VALUE
          let smallest_quote_value = 0;
          let smallest_acc = {};
          let seg_sum = 0;
          for (let cuv in this.calculated_user_values) {
            if (
              smallest_quote_value == 0 ||
              smallest_quote_value >
                this.calculated_user_values[cuv].new_quote_value
            ) {
              smallest_quote_value = this.calculated_user_values[cuv]
                .new_quote_value;
              smallest_acc = this.calculated_user_values[cuv];
            }
            seg_sum += this.calculated_user_values[cuv].new_quote_value;
          }
          let quote_diff = database_quote_value_new - seg_sum;

          smallest_acc.new_quote_value =
            smallest_acc.new_quote_value + quote_diff;

          //CALC NEW PERCENTAGES
          let percentages = [];
          for (let cuv in this.calculated_user_values) {
            // new percent values
            // IF smallest acc == cuv use new quote value from smalles acc
            if (
              this.calculated_user_values[cuv].user_id == smallest_acc.user_id
            ) {
              this.calculated_user_values[cuv].new_share_percentage =
                (smallest_acc.new_quote_value / database_quote_value_new) * 100;
            } else {
              this.calculated_user_values[cuv].new_share_percentage =
                (this.calculated_user_values[cuv].new_quote_value /
                  database_quote_value_new) *
                100;
            }
            // Store Percentages to array
            percentages.push(
              this.calculated_user_values[cuv].new_share_percentage
            );

            this.calculated_user_values[
              cuv
            ].database_quote_value_new = database_quote_value_new;

            this.calculated_user_values[
              cuv
            ].database_quote_value = database_quote_value;
          }
          percentages = percentRound(percentages, 16);

          for (let cuv in this.calculated_user_values) {
            this.calculated_user_values[cuv].new_share_percentage =
              percentages[cuv];
          }
          quote_results.push(this.calculated_user_values);
        }
      }

      console.log(quote_results);
      // this.dialog = true;
    },
  },
};
</script>

<style></style>
