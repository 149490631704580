import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Impressum from "../views/Impressum.vue";
import Dashboard from "../views/Dashboard.vue";
import Orders from "../views/Orders.vue";
import Daily from "../views/Daily.vue";
import Monthly from "../views/Monthly.vue";
import Overall from "../views/Overall.vue";
import Timerange from "../views/TimeRange.vue";
import MgmtDashboard from "../views/MgmtDashboard.vue";
import UserDashboard from "../views/UserDashboard.vue";
import PoolDashboard from "../views/PoolDashboard.vue";
import PoolManagement from "../views/PoolManagement.vue";
import { authGuard } from "../auth/authGuard";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: "/closed_orders",
    name: "Orders",
    component: Orders,
    beforeEnter: authGuard,
  },
  {
    path: "/daily_overview",
    name: "Daily",
    component: Daily,
    beforeEnter: authGuard,
  },
  {
    path: "/monthly_overview",
    name: "Monthly",
    component: Monthly,
    beforeEnter: authGuard,
  },
  {
    path: "/overall_overview",
    name: "Overall",
    component: Overall,
    beforeEnter: authGuard,
  },
  {
    path: "/timerange_overview",
    name: "Timerange",
    component: Timerange,
    beforeEnter: authGuard,
  },
  {
    path: "/management_dashboard",
    name: "Management Dashboard",
    component: MgmtDashboard,
    beforeEnter: authGuard,
  },
  {
    path: "/user_dashboard",
    name: "User Dashboard",
    component: UserDashboard,
    beforeEnter: authGuard,
  },
  {
    path: "/pool_dashboard",
    name: "Pool Dashboard",
    component: PoolDashboard,
    beforeEnter: authGuard,
  },
  {
    path: "/pool_management",
    name: "Poolmanagement",
    component: PoolManagement,
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
