<template>
  <dashboard v-cloak v-slot:default="slotProps">
    <!-- Kompletter Hack, pfusch des jahrhunderts -->
    <span hidden>{{ (selected_user_id = slotProps.user) }}</span>
    <h1>Overall View</h1>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-card-title class="header_color">Overall Statistics</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <!-- <template v-if="daily_metrics">
                      <span>No data available</span>
                    </template> -->
                    <template>
                      <thead>
                        <tr>
                          <th class="text-left"></th>
                          <th class="header_color text-left">
                            EUR
                          </th>
                          <th class="header_color text-left">
                            USD
                          </th>
                          <th
                            v-for="quote in quote_profits"
                            :key="quote.quote"
                            class="text-left"
                          >
                            {{ quote.quote }}
                          </th>
                          <th class="text-left">
                            total profit BTC
                          </th>
                          <th class="text-left">
                            % on account
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="monthly_data_days[0]">
                        <tr>
                          <td>Total</td>
                          <td>{{ monthly_data_days[0].month_profit_eur }}</td>
                          <td>{{ monthly_data_days[0].month_profit_usd }}</td>
                          <td v-for="quote in quote_profits" :key="quote.quote">
                            {{ quote.profit.toFixed(8) }}
                          </td>
                          <td>
                            {{
                              monthly_data_days[0].month_profit_btc.toFixed(8)
                            }}
                          </td>
                          <td>
                            {{
                              monthly_data_days[0]
                                .month_percent_return_on_account
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Per Day</td>
                          <td>
                            {{
                              (
                                monthly_data_days[0].month_profit_eur /
                                monthly_data_days[0].days_so_far
                              ).toFixed(2)
                            }}
                          </td>
                          <td>
                            {{
                              (
                                monthly_data_days[0].month_profit_usd /
                                monthly_data_days[0].days_so_far
                              ).toFixed(2)
                            }}
                          </td>
                          <td v-for="quote in quote_profits" :key="quote.quote">
                            {{
                              (
                                quote.profit / monthly_data_days[0].days_so_far
                              ).toFixed(8)
                            }}
                          </td>
                          <td>
                            {{
                              (
                                monthly_data_days[0].month_profit_btc /
                                monthly_data_days[0].days_so_far
                              ).toFixed(8)
                            }}
                          </td>
                          <td>
                            {{
                              (
                                monthly_data_days[0]
                                  .month_percent_return_on_account /
                                monthly_data_days[0].days_so_far
                              ).toFixed(4)
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-card-title class="header_color">
            <v-row>
              <v-col cols="6">
                Market Pairs
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headers_pairs"
              :items="pairs"
              :search="search"
              :items-per-page="20"
              class="elevation-1"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-card-title class="header_color">Daily Metrics</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="daily_metrics"
                  :expanded.sync="expanded"
                  item-key="date"
                  show-expand
                  :items-per-page="31"
                  class="elevation-1"
                >
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <br />

                      <v-row>
                        <v-col
                          cols="4"
                          v-for="quote in item.quote_profits"
                          :key="quote.quote_id"
                        >
                          <h3 class="header_color">
                            Profit {{ quote.quote_name }}
                          </h3>
                          <v-text-field
                            label="Sells"
                            :value="quote.count_sells_quote"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Profit Quote"
                            :value="quote.sum_profit_quote"
                            :suffix="quote.quote_name"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Profit BTC"
                            :value="quote.sum_profit_btc.toFixed(8)"
                            suffix="BTC"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Profit ETH"
                            :value="quote.sum_profit_eth.toFixed(8)"
                            suffix="ETH"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Profit USD"
                            :value="quote.sum_profit_usd.toFixed(2)"
                            suffix="$"
                            readonly
                          ></v-text-field>
                          <v-text-field
                            label="Profit EUR"
                            :value="quote.sum_profit_eur.toFixed(2)"
                            suffix="€"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </dashboard>
</template>

<script>
import dashboard from "./Dashboard.vue";
import axios from "axios";

export default {
  name: "Daily",
  components: {
    dashboard,
  },
  data() {
    return {
      expanded: [],
      headers: [
        { text: "Month", value: "month" },
        { text: "Date", value: "date" },
        { text: "Trades", value: "sum_trades" },
        { text: "Sells", value: "sum_sells" },
        { text: "Avg % / Trade", value: "avg_percent_profit" },
        { text: "Highest % Profit", value: "max_percent_profit" },
        { text: "Lowest % Profit", value: "min_percent_profit" },
        {
          text: "% Return On Account",
          value: "percent_return_on_account",
        },
        { text: "Sum Profit BTC", value: "sum_profit_btc" },
        { text: "Sum Profit ETH", value: "sum_profit_eth" },
        { text: "Sum Profit USD", value: "sum_profit_usd" },
        { text: "Sum Profit EUR", value: "sum_profit_eur" },
      ],
      headers_pairs: [
        { text: "Quote", value: "quote" },
        { text: "Base", value: "base" },
        { text: "Exchange", value: "exchange" },
        { text: "Sells", value: "sells" },
        { text: "Avg % / Trade", value: "avg_profit_percentage" },
        { text: "MPR", value: "mpr" },
        { text: "MPPR", value: "mppr" },
        { text: "Profit Quote / Trade", value: "avg_quote_profit" },
        { text: "Sum Profit Quote", value: "sum_quote_profit" },
        { text: "Profit BTC / Trade", value: "avg_profit_btc" },
        { text: "Sum Profit BTC", value: "sum_profit_btc" },
        { text: "Sum Profit ETH", value: "sum_profit_eth" },
        { text: "Sum Profit USD", value: "sum_profit_usd" },
        { text: "Sum Profit EUR", value: "sum_profit_eur" },
      ],
      orders: [],
      daily_quote_profits: [],
      daily_metrics: [],
      quote_profits: [],
      monthly_data_days: [],
      pairs: [],
      search: "",
      selected_user_id: 0,
      loading: false,
    };
  },
  mounted() {
    this.selected_user_id = localStorage.getItem("user_id");
  },
  watch: {
    selected_user_id: function() {
      this.getOverallData();
    },
  },
  computed: {},
  methods: {
    async getOverallData() {
      this.loading = true;
      let token = await this.$auth.getTokenSilently();
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/overall_orders?user_id=" +
            this.selected_user_id,
          {
            headers: {
              Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then((response) => {
          this.monthly_data_days = response.data.monthly_data_days;
          this.daily_metrics = response.data.daily_metrics;
          this.quote_profits = response.data.quote_profits;
          this.pairs = response.data.pairs;
          this.loading = false;
        });
    },
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },
};
</script>

<style>
.header_color {
  color: #a16316;
}
</style>
