<template>
  <div>
    <v-navigation-drawer v-model="drawer" absolute left temporary app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title" align="center">
            Apollo Analytics
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list v-if="authenticated" dense nav>
        <v-list-item link to="/management_dashboard">
          <v-list-item-icon>
            <v-icon>mdi-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Management Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item link to="/closed_orders">
          <v-list-item-icon>
            <v-icon>mdi-reorder-horizontal</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Closed Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item link to="/pool_management">
          <v-list-item-icon>
            <v-icon>mdi-reorder-horizontal</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Pools</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/daily_overview">
          <v-list-item-icon>
            <v-icon color="green">mdi-clipboard-list</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Daily View</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/monthly_overview">
          <v-list-item-icon>
            <v-icon color="orange">mdi-clipboard-list</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Monthly View</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/timerange_overview">
          <v-list-item-icon>
            <v-icon color="blue">mdi-clipboard-list</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Timerange View</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/overall_overview">
          <v-list-item-icon>
            <v-icon color="red">mdi-clipboard-list</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Overall View</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/user_dashboard">
          <v-list-item-icon>
            <v-icon color="red">mdi-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>User Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/pool_dashboard">
          <v-list-item-icon>
            <v-icon color="red">mdi-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Pool Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item link to="/user_dashboard">
          <v-list-item-icon>
            <v-icon>mdi-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>User Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <br />
      <div align="center">
        <v-tooltip v-if="!$vuetify.theme.dark" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small fab @click="darkMode">
              <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
            </v-btn>
          </template>
          <span>Dark Mode</span>
        </v-tooltip>

        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small fab @click="darkMode">
              <v-icon>mdi-white-balance-sunny</v-icon>
            </v-btn>
          </template>
          <span>Light Mode</span>
        </v-tooltip>
      </div>
    </v-navigation-drawer>

    <v-app-bar absolute class="toolbar" app>
      <v-app-bar-nav-icon
        color="white"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <router-link to="/">
        <v-img
          class="mx-2"
          src="@/assets/logo_bigger_text_white.svg"
          max-height="48"
          max-width="160"
          contain
        ></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <v-menu bottom left v-if="authenticated">
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined dark v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>{{ selected_user_id }} -
            {{ selected_user_name }}
          </v-btn>
        </template>

        <v-list style="max-height: 300px" class="overflow-y-auto">
          <v-list-item
            v-for="(item, i) in users"
            :key="i"
            @click="setUser(item)"
          >
            <v-list-item-title
              >{{ item.name }} - {{ item.id }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>
      <!-- show logout when authenticated -->
      <v-btn color="white" text v-if="$auth.isAuthenticated" @click="logout">
        <strong>Log out</strong>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <v-container>
        <slot v-bind:user="selected_user_id"></slot>
      </v-container>
    </v-main>
    <v-footer app absolute class="toolbar">
      <v-row justify="center" no-gutters>
        <v-btn text x-small to="/impressum" color="white">
          Impressum
        </v-btn>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      authenticated: false,
      drawer: false,
      users: [],
      selected_user_id: 0,
      selected_user_name: "",
    };
  },
  watch: {
    selected_user_id: function() {
      this.setUserId();
    },
  },
  mounted() {
    this.getJwtToken();
    if (localStorage.getItem("dark_mode") == "false") {
      this.$vuetify.theme.dark = false;
    } else {
      this.$vuetify.theme.dark = true;
    }
  },
  methods: {
    async getJwtToken() {
      let token = await this.$auth.getTokenSilently();
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/authorized", {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        })
        .then((response) => {
          this.authenticated = response.data.authorized;
        })
        .catch(() => {});

      if (this.authenticated == true) {
        this.selected_user_id = localStorage.getItem("user_id");
        this.selected_user_name = localStorage.getItem("user_name");
        this.getUsers();
      } else {
        const path = `/user_dashboard`;
        if (this.$route.path !== path) this.$router.push(path);
      }
    },
    async getUsers() {
      let token = await this.$auth.getTokenSilently();
      let aconfig = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/users", aconfig)
        .then((response) => {
          for (let u in response.data.users) {
            let us = {};
            us.name =
              response.data.users[u].first_name +
              " " +
              response.data.users[u].last_name;
            us.id = "" + response.data.users[u].gsmg_user_id + "";

            this.users.push(us);
          }
        });
    },
    setUser(user) {
      this.selected_user_name = user.name;
      this.selected_user_id = user.id;
    },
    setUserId() {
      localStorage.setItem("user_id", this.selected_user_id);
      localStorage.setItem("user_name", this.selected_user_name);
    },
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_mode", this.$vuetify.theme.dark);
    },
  },
};
</script>

<style></style>
