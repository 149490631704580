<template>
  <dashboard v-cloak>
    <v-row>
      <v-col cols="12">
        <v-card class="rounded-lg" :loading="!loaded_graph1">
          <v-card-text>
            <span class="text-h5 mb-10">
              Geschätzter Gesamtförderungswert
              <v-btn small icon @click="estimated_dialog = true">
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </span>

            <v-container>
              <v-row>
                <template v-if="loaded_graph1">
                  <v-col sm="12" md="12" lg="4">
                    <v-toolbar class="rounded-lg mb-2 elevation-3">
                      <v-app-bar-nav-icon disabled>
                        <v-icon large id="sigma">mdi-sigma</v-icon>
                      </v-app-bar-nav-icon>
                      <v-toolbar-title>
                        Gesamt
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-list
                      outlined
                      align="left"
                      class="rounded-lg elevation-3"
                    >
                      <v-list-item class="lighten-4">
                        <v-list-item-content>
                          <template
                            v-if="exchange_values[0] && exchange_values[1]"
                          >
                            <v-list-item-title>
                              {{
                                (
                                  exchange_values[0].account_btc +
                                  exchange_values[1].account_btc
                                ).toFixed(8)
                              }}
                            </v-list-item-title>
                          </template>
                          <template v-else-if="exchange_values[0]">
                            <v-list-item-title>
                              {{ exchange_values[0].account_btc.toFixed(8) }}
                            </v-list-item-title>
                          </template>
                          <v-list-item-subtitle>
                            BTC
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                      <v-list-item>
                        <v-list-item-content>
                          <template
                            v-if="exchange_values[0] && exchange_values[1]"
                          >
                            <v-list-item-title>
                              {{
                                (
                                  exchange_values[0].account_eur +
                                  exchange_values[1].account_eur
                                ).toFixed(2)
                              }}
                            </v-list-item-title>
                          </template>
                          <template v-else-if="exchange_values[0]">
                            <v-list-item-title>
                              {{ exchange_values[0].account_eur.toFixed(2) }}
                            </v-list-item-title>
                          </template>
                          <v-list-item-subtitle>
                            EUR
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                      <v-list-item>
                        <v-list-item-content>
                          <template
                            v-if="exchange_values[0] && exchange_values[1]"
                          >
                            <v-list-item-title>
                              {{
                                (
                                  exchange_values[0].account_usd +
                                  exchange_values[1].account_usd
                                ).toFixed(2)
                              }}
                            </v-list-item-title>
                          </template>
                          <template v-else-if="exchange_values[0]">
                            <v-list-item-title>
                              {{ exchange_values[0].account_usd.toFixed(2) }}
                            </v-list-item-title>
                          </template>
                          <v-list-item-subtitle>
                            USD
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </template>
                <v-col
                  sm="12"
                  md="12"
                  lg="4"
                  v-for="exchange in exchange_values"
                  v-bind:key="exchange.exchange"
                >
                  <v-toolbar class="rounded-lg mb-2 elevation-3">
                    <v-app-bar-nav-icon disabled>
                      <v-img
                        v-if="exchange.exchange_id == 1"
                        class="ma-2"
                        src="@/assets/Binance_logo.svg"
                        contain
                      ></v-img>
                      <v-img
                        v-else
                        class="ma-2"
                        src="@/assets/Bittrex_logo.svg"
                        contain
                      ></v-img>
                    </v-app-bar-nav-icon>

                    <v-toolbar-title>
                      {{ exchange.exchange }}
                    </v-toolbar-title>
                  </v-toolbar>

                  <v-list outlined align="left" class="rounded-lg elevation-3">
                    <v-list-item class="lighten-4">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ exchange.account_btc.toFixed(8) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          BTC
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ exchange.account_eur.toFixed(2) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          EUR
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ exchange.account_usd.toFixed(2) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          USD
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="6">
        <v-card class="rounded-lg" :loading="!loaded_graph1">
          <v-card-text>
            <span class="text-h5 mb-10"
              >Historische Gesamtförderungswerte
              <v-btn small icon @click="historical_dialog = true">
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </span>
            <v-container fluid>
              <Chart
                v-bind:config="total_acc_graph_data"
                v-bind:chart_id="'total-acc-value'"
                v-if="loaded_graph1"
              />
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="6">
        <v-card class="rounded-lg" :loading="!loaded_graph1">
          <v-card-text>
            <span class="text-h5 mb-10">
              Aktuelle Forschungsaktivitäten
              <v-btn small icon @click="activity_dialog = true">
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </span>
            <v-container fluid>
              <Chart
                v-bind:config="total_trades_graph"
                v-bind:chart_id="'compl-trade-pairs'"
                v-if="loaded_graph2"
              />
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="rounded-lg" :loading="!loaded_graph1">
          <v-card-text>
            <div class="pb-7">
              <span class="text-h5">
                Aufschlüsselung des Gesamtförderungswertes
                <v-btn small icon @click="fractional_dialog = true">
                  <v-icon>mdi-help-circle-outline</v-icon>
                </v-btn>
              </span>
            </div>

            <v-row>
              <v-col
                md="6"
                sm="12"
                xs="12"
                v-for="quote in quote_graphs"
                :key="quote.quote"
              >
                <v-card class="rounded-lg elevation-8">
                  <v-card-text>
                    <div class="mb-10">
                      <span class="text-h6 ">{{ quote.quote }}</span>
                    </div>
                    <Chart
                      v-bind:config="quote.graph_conf"
                      v-bind:chart_id="quote.quote"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" v-if="exchange_profits">
        <v-tabs
          v-model="exchange_tab"
          @change="quote_tab = 0"
          background-color="transparent"
        >
          <v-tabs-slider color="transparent"></v-tabs-slider>
          <v-tab
            id="exchange_results_tab"
            class="mr-2 rounded-t-lg"
            v-bind:class="[
              $vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4',
            ]"
            v-for="(exc, i) in exchange_profits"
            :key="i"
            :href="'#' + i"
          >
            <v-img
              class="mr-2"
              src="@/assets/Binance_logo.svg"
              contain
              v-if="i == 'Binance'"
            ></v-img>
            <v-img
              class="mr-2"
              src="@/assets/Bittrex_logo.svg"
              contain
              v-else
            ></v-img>
            {{ i }}
          </v-tab>
        </v-tabs>
        <v-tabs-items class="rounded-lg rounded-tl-0" v-model="exchange_tab">
          <v-tab-item :value="exchange_tab">
            <v-card
              class="rounded-tl-0"
              v-bind:class="[
                $vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4',
              ]"
            >
              <v-card-text>
                <span class="text-h4 my-5 centered-content">
                  Resultate
                  <v-btn
                    class="mt-1 ml-1"
                    small
                    icon
                    @click="results_dialog = true"
                  >
                    <v-icon>mdi-help-circle-outline</v-icon>
                  </v-btn>
                </span>
              </v-card-text>
              <v-tabs
                v-model="quote_tab"
                centered
                :background-color="
                  $vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2'
                "
              >
                <v-tab
                  class="rounded-t-0"
                  v-for="(quotes, j) in exchange_profits[exchange_tab]"
                  :key="j"
                  :href="'#' + j"
                >
                  {{ j }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="quote_tab">
                <v-tab-item :value="quote_tab">
                  <v-card
                    v-if="quote_tab"
                    v-bind:class="[
                      $vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4',
                    ]"
                  >
                    <v-card-text>
                      <v-row>
                        <v-col md="4" sm="12" xs="12" class="pa-10">
                          <v-row>
                            <v-col cols="12" class="pa-1">
                              <span class="text-h5 centered-content"
                                >{{ quote_tab }}
                              </span>
                            </v-col>
                            <v-col cols="12" class="pa-1 centered-content"
                              ><div>
                                <span class="text-h2"
                                  >{{
                                    exchange_profits[exchange_tab][quote_tab][
                                      "7"
                                    ]
                                      ? exchange_profits[exchange_tab][
                                          quote_tab
                                        ]["7"].profit.toFixed(5)
                                      : 0
                                  }} </span
                                ><span class="left-content" align="end"
                                  >USD
                                  {{
                                    exchange_profits[exchange_tab][quote_tab][
                                      "7"
                                    ]
                                      ? exchange_profits[exchange_tab][
                                          quote_tab
                                        ]["7"].profit_usd.toFixed(0)
                                      : 0
                                  }}
                                  <v-btn
                                    x-small
                                    icon
                                    @click="usd_dialog = true"
                                  >
                                    <v-icon>mdi-help-circle-outline</v-icon>
                                  </v-btn>
                                </span>
                              </div>
                            </v-col>
                            <v-col cols="12" class="pa-1 "
                              ><span class="centered-content bottom">
                                Resultat der letzten 7 Tage</span
                              ></v-col
                            >
                          </v-row>
                        </v-col>
                        <v-col md="4" sm="12" xs="12" class="pa-10">
                          <v-row>
                            <v-col cols="12" class="pa-1">
                              <span class="text-h5 centered-content"
                                >{{ quote_tab }}
                              </span>
                            </v-col>
                            <v-col cols="12" class="pa-1 centered-content"
                              ><div>
                                <span class="text-h2"
                                  >{{
                                    exchange_profits[exchange_tab][quote_tab][
                                      "30"
                                    ]
                                      ? exchange_profits[exchange_tab][
                                          quote_tab
                                        ]["30"].profit.toFixed(5)
                                      : 0
                                  }} </span
                                ><span class="left-content" align="end"
                                  >USD
                                  {{
                                    exchange_profits[exchange_tab][quote_tab][
                                      "30"
                                    ]
                                      ? exchange_profits[exchange_tab][
                                          quote_tab
                                        ]["30"].profit_usd.toFixed(0)
                                      : 0
                                  }}
                                  <v-btn
                                    x-small
                                    icon
                                    @click="usd_dialog = true"
                                  >
                                    <v-icon>mdi-help-circle-outline</v-icon>
                                  </v-btn>
                                </span>
                              </div>
                            </v-col>
                            <v-col cols="12" class="pa-1 "
                              ><span class="centered-content bottom">
                                Resultat der letzten 30 Tage</span
                              ></v-col
                            >
                          </v-row>
                        </v-col>
                        <v-col md="4" sm="12" xs="12" class="pa-10">
                          <v-row>
                            <v-col cols="12" class="pa-1">
                              <span class="text-h5 centered-content">{{
                                quote_tab
                              }}</span>
                            </v-col>
                            <v-col cols="12" class="pa-1 centered-content"
                              ><div>
                                <span class="text-h2"
                                  >{{
                                    exchange_profits[exchange_tab][quote_tab][
                                      "alltime"
                                    ]
                                      ? exchange_profits[exchange_tab][
                                          quote_tab
                                        ]["alltime"].profit.toFixed(5)
                                      : 0
                                  }}
                                </span>
                              </div>
                            </v-col>
                            <v-col cols="12" class="pa-1 "
                              ><span class="centered-content bottom">
                                Resultat über die Gesamtlaufzeit</span
                              ></v-col
                            >
                          </v-row>
                        </v-col>
                        <!-- <v-col cols="3" class="pa-10">
                          <v-row>
                            <v-col cols="12" class="pa-1">
                              <span class="text-h5 centered-content">BNB </span>
                            </v-col>
                            <v-col cols="12" class="pa-1 centered-content"
                              ><div>
                                <span class="text-h2">0.07002 </span
                                ><span class="left-content" align="end"
                                  >USD 29</span
                                >
                              </div>
                            </v-col>
                            <v-col cols="12" class="pa-1 "
                              ><span class="centered-content bottom">
                                Unrealised loss<v-btn
                                  icon
                                  @click="loss_dialog = true"
                                >
                                  <v-icon
                                    >mdi-help-circle-outline</v-icon
                                  ></v-btn
                                ></span
                              ></v-col
                            >
                          </v-row>
                        </v-col> -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-dialog v-model="estimated_dialog" width="600">
      <v-card>
        <v-card-title class="text-h5 ">
          Geschätzter Gesamtförderungswert
        </v-card-title>

        <br />

        <v-card-text>
          <p>
            Hier wird der Gesamtförderungswert des jeweiligen Mitglieds
            angezeigt, welcher eine Momentaufnahme aller Bestände darstellen
            soll. "Geschätzt" deswegen, da die Genauigkeit nur begrenzt
            gewährleistet werden kann, weil die Kurse der Währungen schnell
            schwanken und sich diese Werte dadurch rasant ändern können.
          </p>
          <p>
            Die geschätzten Werte werden in Bitcoin (BTC), Euro (EUR) und U.S.
            Dollar (USD) angezeigt. Diese stellen den Gesamtförderungswert in
            unterschiedlichen Währungen dar und sollten nicht addiert werden.
            Zur Nachvollziehbarkeit wird hier eine Unterscheidung zwischen den
            Exchanges getroffen.
          </p>
          <p>
            Diese Werte werden alle 30 Minuten neu berechnet, sofern neue Daten
            bereits von den Exchanges abgegriffen worden sind. Im schlimmsten
            Fall liegen die berechneten Werte so weit zurück, wie der letzte
            Zeitpunkt des Einholens der Daten, von den jeweiligen Exchanges.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="estimated_dialog = false">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="historical_dialog" width="600">
      <v-card>
        <v-card-title class="text-h5 ">
          Historische Gesamtförderungswerte
        </v-card-title>

        <br />

        <v-card-text>
          <p>
            Die historischen Gesamtförderungswerte bieten die Möglichkeit, die
            durchschnittlichen Werte des jeweiligen Tages, rückwirkend für die
            letzten zwei Monate, einzusehen. Hierbei werden alle Daten des Tages
            berechnet und der Durchschnittswert als Datenpunkt, für den
            jeweiligen Tag, im Graphen abgebildet.
          </p>
          <p>
            Die Werte werden in Bitcoin (BTC) und U.S. Dollar (USD) angezeigt.
            Wie auch oben im Dashboard, werden die Gesamtförderungswerte in
            unterschiedlichen Währungen dargestellt und sollten nicht addiert
            werden. Hierbei werden die Werte der verschiedenen Exchanges (falls
            mehr als ein Exchange zur Forschung verwendet wird) addiert und
            final als ein Datenpunkt abgebildet.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="historical_dialog = false">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="activity_dialog" width="600">
      <v-card>
        <v-card-title class="text-h5 ">
          Aktuelle Forschungsaktivitäten
        </v-card-title>

        <br />

        <v-card-text>
          <p>
            Bei den aktuellen Forschungsaktivitäten werden zwei Werte
            abgebildet. Einerseits die Anzahl der Bewegungen und andererseits
            die Bewegungsresultate in U.S. Dollar (USD), rückwirkend für die
            letzten 7 Tage.
          </p>
          <p>
            Die Anzahl der Bewegungen ist die Summe aller Tätigkeiten für den
            jeweiligen Tag, die durch die Forschung auf den Exchanges statt
            fand.
          </p>
          <p>
            Die Bewegungsresultate in U.S. Dollar (USD) sind ein historischer
            Richtwert, damit die Forschungsergebnisse für die Mitglieder etwas
            greifbarer werden. Tatsächlich sind jedoch diese Werte nur
            umgerechnet (zum Zeitpunkt einer Bewegung), denn die Forschung
            verfolgt es nicht Resultate in USD zu erzielen.
          </p>
          <p>
            Korrekterweise müssten die veschiedenen Resultate der
            unterschiedlichen Kryptowährungen aufgelistet werden, damit eine
            korrekt Abbildung der Forschung gewährleistet werden kann. Zum
            einfacheren Verständnis werden alle Resultate des Tages der
            jeweiligen Währungen in USD umgerechnet, um einen simplen Datenpunkt
            darstellen zu können.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="activity_dialog = false">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="fractional_dialog" width="600">
      <v-card>
        <v-card-title class="text-h5 ">
          Aufschlüsselung des Gesamtförderungswertes
        </v-card-title>

        <br />

        <v-card-text>
          <p>
            Bei der Aufschlüsselung des Gesamtförderungswertes, bieten wir die
            Möglichkeit, die durchschnittlichen Werte einer Währung des
            jeweiligen Tages, rückwirkend für die letzten zwei Monate,
            einzusehen.
          </p>
          <p>
            Die Werte bilden die durchschnittliche Anzahl der jeweiligen Währung
            des Tages ab. Hierbei werden alle Daten der verschiedenen Exchanges
            (falls mehr als ein Exchange zur Forschung verwendet wird) addiert
            und der Durchschnitt des Tages, final als ein Datenpunkt abgebildet.
          </p>
          <p>
            Da man sich bei diesem Forschungsprojekt permanent in
            verschiedensten Währungen befindet, muss man beachten, dass sich die
            Kurse auch innerhalb des Tages rasant und erheblich verändern
            können. Je nachdem wie die anderen Währungen, zwischen welchen wir
            uns bewegen, gerade zu der Notierungswährung (Währung des Graphen)
            stehen, kann es auch zu enormen Schwankungen innerhalb dieses
            Graphen kommen.
          </p>
          <p>
            Dies sind jene Währungen, für die dieses Forschungsprojekt
            bestenfalls Resultate erzielen möchten. Je nach Mitglied und
            Forschungsziel können hier unterschiedliche Währungen aufgelistet
            sein.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="fractional_dialog = false">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="results_dialog" width="600">
      <v-card>
        <v-card-title class="text-h5 ">
          Resultate
        </v-card-title>

        <br />

        <v-card-text>
          <p>
            Dies sind die Resultate aus dem Forschungsprojekt. Sie sind
            aufgeteilt in die jeweiligen Exchanges und in die Währungen, für die
            dieses Forschungsprojekt bestenfalls Resultate erzielen möchte. Je
            nach Mitglied und Forschungsziel können hier unterschiedliche
            Währungen aufgelistet sein.
          </p>
          <p>
            Eine Unterteilung für die letzten 7 und 30 Tage, als auch eine Summe
            über die Gesamtlaufzeit der jeweiligen Währung, wird aufgelistet.
            Zusätzlich wird ein umgerechneter, historischer Wert in U.S. Dollar
            (USD) neben der tatsächlichen Anzahl der Währung angezeigt.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="results_dialog = false">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="usd_dialog" width="600">
      <v-card>
        <v-card-title class="text-h5 ">
          Historischer Wert in U.S. Dollar (USD)
        </v-card-title>

        <br />

        <v-card-text>
          <p>
            Mit dem historischen Wert in U.S. Dollar (USD), wird ein ungefährer
            Richtwert zum aktuellen Kurs geboten.
          </p>
          <p>
            Dieser Wert bildet die Summe der Währung in USD ab, zum Zeitpunkt
            der jeweiligen Bewegungen für die betreffende Zeitspanne (7 Tage/30
            Tage). Das bedeutet, wenn das Resultat der Forschung direkt in USD
            bewegt worden wäre, würde dieser Wert das equivalente Resultat in
            USD widerspiegeln.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="usd_dialog = false">
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </dashboard>
</template>
<script>
import axios from "axios";
import dashboard from "./Dashboard.vue";
import Chart from "../components/chart.vue";

export default {
  components: {
    dashboard,
    Chart,
  },
  data() {
    return {
      exchange_tab: 0,
      active_exchange: "",
      active_quote: "",
      quote_tab: 0,
      estimated_dialog: false,
      historical_dialog: false,
      activity_dialog: false,
      fractional_dialog: false,
      results_dialog: false,
      usd_dialog: false,
      total_trades_graph: {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: "Anzahl Bewegungen",
              fill: false,
              data: [],
              borderColor: "#D2B07F",
              backgroundColor: "#D2B07F",
              yAxisID: "y",
            },
            {
              label: "Bewegungsresultate (USD)",
              fill: false,
              data: [],
              borderColor: "#001D2C",
              backgroundColor: "#001D2C",
              yAxisID: "y1",
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                id: "y",
                type: "linear",
                position: "left",
              },
              {
                id: "y1",
                type: "linear",
                position: "right",
                gridLines: {
                  display: false,
                },
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value) {
                        return   '$ ' + value;
                    }
                }
              },
            ],
          },
        },
      },
      quote_graphs: [],
      total_acc_graph_data: {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: "Gesamtförderungswert (BTC)",
              fill: false,
              data: [],
              borderColor: "#D2B07F",
              backgroundColor: "#D2B07F",
              yAxisID: "y",
            },
            {
              label: "Gesamtförderungswert (USD)",
              fill: false,
              data: [],
              borderColor: "#001D2C",
              backgroundColor: "#001D2C",
              yAxisID: "y1",
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                id: "y",
                type: "linear",
                position: "right",
                gridLines: {
                  display: false,
                }
              },
              {
                id: "y1",
                type: "linear",
                position: "left",
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function(value) {
                      return   '$ ' + value;
                  }
                }
              }
            ],
          },
        },
      },
      date: new Date().toISOString().substr(0, 10),
      exchange_values: 0,
      exchange_profits: 0,
      loaded_graph1: false,
      loaded_graph2: false,
    };
  },
  computed: {},
  mounted() {
    this.getDashboardData();
  },
  methods: {
    async getDashboardData() {
      let token = await this.$auth.getTokenSilently();

      await axios
        .get(process.env.VUE_APP_API_URL + "/api/user_dashboard", {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        })
        .then((response) => {
          this.exchange_values = response.data.account_values;
          this.exchange_profits = response.data.exchange_profits;
          this.setAccValueGraph(response.data.account_values_graph);
          this.setTotalTradeGraph(response.data.total_trades_graph);
          this.setQuoteGraphs(response.data.segmented_values_graph);
        });
    },
    setTotalTradeGraph(trades) {
      let total_trades = [];
      let profits = [];
      for (let t in trades) {
        this.total_trades_graph.data.labels.indexOf(trades[t].date) === -1
          ? this.total_trades_graph.data.labels.push(trades[t].date)
          : 0;

        total_trades.push(trades[t].trades);
        profits.push(trades[t].sum_usd);
      }
      this.total_trades_graph.data.datasets[0].data = total_trades;
      this.total_trades_graph.data.datasets[1].data = profits;
      this.loaded_graph2 = true;
    },
    setAccValueGraph(account_values_graph) {
      let usd_account = [];
      let btc_account = [];

      for (let v in account_values_graph) {
        // lables x-axis
        this.total_acc_graph_data.data.labels.indexOf(
          account_values_graph[v].tag
        ) === -1
          ? this.total_acc_graph_data.data.labels.push(
              account_values_graph[v].tag
            )
          : 0;
          if(account_values_graph[v].account_btc &&account_values_graph[v].account_usd){
        btc_account.push(account_values_graph[v].account_btc.toFixed(5));
        usd_account.push(account_values_graph[v].account_usd.toFixed(2));}else{
          btc_account.push(0);
        usd_account.push(0);
        }
      }

      this.total_acc_graph_data.data.datasets[0].data = btc_account;
      this.total_acc_graph_data.data.datasets[1].data = usd_account;

      this.loaded_graph1 = true;
    },
    setQuoteGraphs(segmented_values_graph) {
      for (let svg in segmented_values_graph) {
        let quote_graph = {};
        quote_graph.quote = svg;
        let days = [];
        let values = [];
        for(let q in segmented_values_graph.[svg] ){
          days.push(segmented_values_graph.[svg].[q].day);
          values.push(segmented_values_graph.[svg].[q].quote_value.toFixed(4));
        }
        let graph_conf = {
        type: "line",
        data: {
          labels: days,
          datasets: [
            {
              label: quote_graph.quote,
              fill: false,
              data: values,
              borderColor: "#D2B07F",
              backgroundColor: "#D2B07F",
            },
          ],
        },
        options: {
          responsive: true,
        },
      };
      quote_graph.graph_conf = graph_conf;
      this.quote_graphs.push(quote_graph);
      }
    },
  },
};
</script>

<style scoped>
.v-tab:hover {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
#exchange_results_tab:before {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}
#sigma {
  color: #d2b07f !important;
}
</style>
